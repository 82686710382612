import { Injectable } from '@angular/core';
import { ediconstant } from 'src/app/constant/ediconstant';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';

@Injectable({
  providedIn: 'root'
})

export class VendorService {

  constructor(private baseHttpService: BaseHttpService) { }

  createFullVendor(vendorData: any) {
    return this.baseHttpService.post<any>(ediconstant.createFullVendorUrl, vendorData, ediconstant.httpClientHeader.headers);
  }

  getMasterVendor(vendorData: any) {
    return this.baseHttpService.post<any>(ediconstant.getMasterVendorUrl, vendorData, ediconstant.httpClientHeader.headers);
  }

  getMasterVendorConnectionAndField(vendorData: any) {
    return this.baseHttpService.post<any>(ediconstant.getMasterVendorConnAndFieldsUrl, vendorData, ediconstant.httpClientHeader.headers);
  }

  getVendorConnectionAndField(vendorData: any) {
    return this.baseHttpService.post<any>(ediconstant.getVendorConnAndFieldsUrl, vendorData, ediconstant.httpClientHeader.headers);
  }

  getVendorData(vendorData: any) {
    return this.baseHttpService.post<any>(ediconstant.getVendorUrl, vendorData, ediconstant.httpClientHeader.headers);
  }

  getVendorWithContractData(vendorData: any) {
    return this.baseHttpService.post<any>(ediconstant.getVendorWithContractUrl, vendorData, ediconstant.httpClientHeader.headers);
  }

  saveVendorWithContractData(vendorData: any) {
    return this.baseHttpService.post<any>(ediconstant.saveVendorWithContractUrl, vendorData, ediconstant.httpClientHeader.headers);
  }

  getFullActiveVendorsByCode(vendorData: any) {
    return this.baseHttpService.post<any>(ediconstant.getFullActiveVendorsByCodeUrl, vendorData, ediconstant.httpClientHeader.headers);
  }

  mapFullVendorWithNPI(vendorData: any) {
    return this.baseHttpService.post<any>(ediconstant.mapFullVendorWithNPINoUrl, vendorData, ediconstant.httpClientHeader.headers);
  }

  deleteMasterVendor(vendorData: any) {
    return this.baseHttpService.post<any>(ediconstant.deleteMasterVendorUrl, vendorData, ediconstant.httpClientHeader.headers);
  }

  updateMasterVendor(vendorData: any) {
    return this.baseHttpService.post<any>(ediconstant.updateMasterVendorUrl, vendorData, ediconstant.httpClientHeader.headers);
  }

  updateVendorConnection(vendorConnData: any) {
    return this.baseHttpService.post<any>(ediconstant.updateVendorConnectionUrl, vendorConnData, ediconstant.httpClientHeader.headers);
  }

  updateVendorInformation(vendorInfoData: any) {
    return this.baseHttpService.post<any>(ediconstant.updateVendorInformationUrl, vendorInfoData, ediconstant.httpClientHeader.headers);
  }

  getFTPScheduler() {
    return this.baseHttpService.get<any>(ediconstant.getFTPSchedulerUrl, ediconstant.httpClientHeader.headers);
  }

  SaveFTPScheduler(storeGeneralConfigData: any) {
    return this.baseHttpService.post<any>(ediconstant.saveFTPSchedulerUrl, storeGeneralConfigData, ediconstant.httpClientHeader.headers);
  }

  createVendorContract(vendorWithContarct: any) {
    return this.baseHttpService.post<any>(ediconstant.saveVendorWithContractUrl, vendorWithContarct, ediconstant.httpClientHeader.headers);
  }

  deleteVendorContract(vendorWithContarct: any) {
    return this.baseHttpService.post<any>(ediconstant.deleteVendorWithContractUrl, vendorWithContarct, ediconstant.httpClientHeader.headers);
  }

  testVendorFtpConnection(vendorWithContarct: any) {
    return this.baseHttpService.post<any>(ediconstant.testVendorFtpConnectionUrl, vendorWithContarct, ediconstant.httpClientHeader.headers);
  }

  checkIfContractAlreadyExists(vendorWithContarct: any) {
    return this.baseHttpService.post<any>(ediconstant.checkIfContractAlreadyExists, vendorWithContarct, ediconstant.httpClientHeader.headers);
  }

  deleteNPIMappedVendors(request: any) {
    return this.baseHttpService.post<any>(ediconstant.deleteNPIMappedVendors, request, ediconstant.httpClientHeader.headers);
  }
}
