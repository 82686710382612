import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ediconstant } from 'src/app/constant/ediconstant';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';

@Injectable({
  providedIn: 'root'
})
export class GetFilesNowService {

  constructor(private baseHttpService: BaseHttpService) { }

  getNowFileDownload(): Observable<any> {
    return this.baseHttpService.post<any>(ediconstant.fileDownloadGetNowScheduler, ediconstant.httpClientHeader.headers);
  }

  getNowPriceUpdate832(): Observable<any> {
    return this.baseHttpService.get<any>(ediconstant.getNowPrice832Scheduler, ediconstant.httpClientHeader.headers);
  }

  getNowOrder810(): Observable<any> {
    return this.baseHttpService.get<any>(ediconstant.getNowOrder810Scheduler, ediconstant.httpClientHeader.headers);
  }

  getNowOrder855(): Observable<any> {
    return this.baseHttpService.get<any>(ediconstant.getNowOrder855Scheduler, ediconstant.httpClientHeader.headers);
  }
}
