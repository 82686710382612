import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApiResult, GenSys, constant } from 'src/app/constant/constant';
import { HttpClient } from '@angular/common/http';
import { StoreService } from 'src/app/services/store.service';
import { EDIStoreService } from 'src/app/services/edi_store.service';
import { MPOSStoreService } from 'src/app/services/mpos_store.service';
import { UtilityService } from 'src/app/services/utility.service';
import { stateConstant } from 'src/app/constant/stateConstant';
import { Observable, Subject, catchError, concatMap, forkJoin, map, of, throwError, takeUntil, startWith } from 'rxjs';
import { StoreAdminService } from 'src/app/services/storeAdmin.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { StoreRequest } from 'src/app/models/StoreRequest';
import { PrimerxCloudPharmacyService } from 'src/app/services/primerx-cloud-pharmacy.service';
import { PharmacyCreateRequest } from 'src/app/models/Pharmacy/PrimeRxCloudPharmacyCreateRequest';
import { MasterDB } from 'src/app/models/Pharmacy/MasterDB';
import { PayType } from 'src/app/models/PayType';
import { PayTypeMasterService } from 'src/app/services/pay-type-master.service';
import { PayTypeOption } from 'src/app/models/StoreRequest';
import { OrganizationSearchRequest } from 'src/app/models/RequestModel/OrganizationSearchRequest';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ItemService } from 'src/app/services/Item/item.service';
import { StateMasterService } from 'src/app/services/StateMaster/statemaster.service';

import { NgForm } from '@angular/forms';
import { EDIStoreAdminService } from 'src/app/services/edi_storeAdmin.service';
import { MPOSStoreAdminService } from 'src/app/services/mpos_storeAdmin.service';

@Component({
  selector: 'app-store-modal',
  templateUrl: './store-modal.component.html',
  styleUrls: ['./store-modal.component.css']
})
export class StoreModalComponent implements OnInit {

  @Input() modal_title: any;
  @ViewChild('storeModalForm') storeModalForm: NgForm;
  @ViewChild('closebutton')

  closebutton!: { nativeElement: { click: () => void; }; };

  raw: never[];

  fullStoreData: any = {};

  constructor(@Inject(MAT_DIALOG_DATA) public receivedData: any, private http: HttpClient, private payTypeMasterService: PayTypeMasterService,
    private storeService: StoreService, private ediStoreService: EDIStoreService,
    private mposStoreService: MPOSStoreService, private utilityService: UtilityService,
    private storeAdminService: StoreAdminService, private dialogPayService: MatDialog,
    private organizationService: OrganizationService,
    private primerxCloudPharmacyService: PrimerxCloudPharmacyService,
    private cdr: ChangeDetectorRef, private dialogService: MatDialogRef<StoreModalComponent>,
    private mpostore: MPOSStoreService,
    private stateMasterService: StateMasterService, //EPPOS-3755
    private itemService: ItemService, //EPPOS-3788,
    private EDIstoreAdminService: EDIStoreAdminService,
    private MPOSstoreAdminService: MPOSStoreAdminService
  ) {
    this.getPayTypes();
    this.getOrganizations();

    if (this.receivedData.userData != undefined) {
      this.Store = this.receivedData.userData;
      // If updating, keep the existing value of allowDefaultItems
      if (this.Store.allowDefaultItems === undefined) {
        this.Store.allowDefaultItems = true; // Set default if it's not defined //#EPPOS-3788
      }
    } else {
      this.Store.isActive = true;
      this.Store.primeRxCloudData = {};
      this.Store.primeRxCloudData.adminUserName = "admin";
      this.Store.allowDefaultItems = true; // Ensure this is true by default for new entries //#EPPOS-3788
    }
  }

  stateArray: any[] = stateConstant.states;//getting the item type
  Store: any = {};
  StoreAdmin: any = {};
  message: any = '';
  GenSys: GenSys;
  genSysOptions: any[];
  organizationArray: any[];
  pharmacyCreateRequest: PharmacyCreateRequest;
  masterDBArray: MasterDB[] = [];
  selectedPayType: string[] = [];
  selPayType: { payTypeID: string; payTypeDesc: string; payType: string; };
  selectedPayTypes: PayType[] = [];
  payTypeOptions: PayTypeOption[] = [];
  selectedPayTypeArray: PayType[] = [];
  showPayTypeArray: any[] = [];
  storePayTypeArray: any[] = [];
  payData: any[] = [];
  payTypes: { NPINo: string, payTypeID: string; payTypeDesc: string; payType: string; }[] = [];
  customPayTypes: { NPINo: string, payTypeID: string; payTypeDesc: string; payType: string; }[] = [];
  customPayType: PayTypeOption | undefined;
  disabledPayTypeIDs: string[] = [];
  customPayTypeSelected: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();
  showError: boolean = false;
  panelOpenState: boolean = false;
  isPasswordStrong: boolean = false;
  hide: boolean = true;
  cityArray: any[] = [];
  organizationReq: OrganizationSearchRequest = new OrganizationSearchRequest;
  isInfoLog: boolean = false; // Default value #3787
  allowDefaultItems: boolean = true; //Default value #3788
  searchInput='';
  filteredOrganizations: any[];
  //#region DROPDOWN STATE CITY
  stateCtrl: FormControl;
  filteredState: Observable<any[]>;
  cityCtrl: FormControl;
  filteredCity: Observable<any[]>;
  //#endregion

  ngOnInit(): void {
    this.genSysOptions = Object.keys(GenSys)
      .filter((key) => isNaN(Number(key)))
      .map((key) => ({ label: key, value: GenSys[key] }));

    this.getMasterDB();

    this.IntializeStateCtrl();
    this.IntializeCityCtrl();

    if (this.Store != undefined && this.Store != null && this.Store.state != undefined && this.Store.state != "")
      this.loadCityForCurrentState(this.Store.state);

    this.getPayTypes();
    this.getOrganizations();
    this.getUserStore();

  }

  //#region EPPOS-3755
  // Method to fetch state and city based on ZIP code
  fetchStateAndCity(): void {
    if (this.Store.zipCode && this.Store.zipCode.length != 1) {
      this.stateMasterService.searchStateCityByZip(this.Store.zipCode).subscribe({
        next: (response) => {
          if (response.result === ResponseStatus.SUCCESS) {
            const data = response.data;

            // Find state in stateArray and set it in the state control
            const state = this.stateArray.find(s => s.abbreviation === data.state.name);
            if (state) {
              this.stateCtrl.setValue(state.stateName);
              this.Store.state = state.abbreviation;
              this.loadCityForCurrentState(state.abbreviation);
            }

            // Set city control and organization city from response
            this.cityCtrl.setValue(data.city.name || '');
            this.Store.city = data.city.name || '';

          } else {
            this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
          }
        },
        error: (error) => {
          console.error('Error fetching state and city:', error);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, 'Failed to fetch state and city data');
        }
      });
    }
  }
  //#endregion

  selectedPayTypemethod(): string {
    let payTypesString = '';

    this.selectedPayTypeArray.forEach(selectedPayType => {
      if (typeof selectedPayType === 'string') {
        payTypesString += selectedPayType + ', ';
      } else {
        const payType = selectedPayType.payTypeID ? selectedPayType.payTypeID : selectedPayType.payType;
        payTypesString += payType + ', ';
      }
    });

    payTypesString = payTypesString.slice(0, -2);

    return payTypesString;
  }

  isPayTypeDisabled(payTypeID: string): boolean {
    return this.disabledPayTypeIDs.includes(payTypeID);
  }

  getUserStore(): void {
    const store = {
      NPINo: this.Store.NPINo
    }
    this.mposStoreService.SearchStore(store).subscribe(response => {

      if (response.result == "SUCCESS") {
        var receivedStore = JSON.parse(response.data);
        this.fullStoreData = receivedStore.retstore;

      } else {
        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while getting the store data");
      }

    });
  }

  getPayTypes(): void {
    this.payTypeMasterService.GetPayTypeMasterAsync().pipe(
      catchError((error: any) => {
        return throwError(error);
      })
    ).subscribe((response: any) => {
      if (response) {
        const data = JSON.parse(response.data);
        const payTypeDescSet = new Set<string>();
        this.payData = data.PaytypeMaster.filter(payType => (payType.NPINo === "" || payType.NPINo === undefined) && payType.isHide === false);
        this.customPayTypes = data.PaytypeMaster.filter(payType => payType.payType === constant.CUSTOM_PAYMENT && payType.NPINo === this.Store.NPINo);
        this.payData = this.payData.filter(payType => payType.payType != constant.CUSTOM_PAYMENT);

        this.payTypes = this.payData.map(item => ({
          NPINo: '',
          payTypeID: item.payTypeID,
          payTypeDesc: item.payTypeDesc,
          payType: item.payType
        }));

        const paytypeDataByNpi = data.PaytypeMaster.filter(payType => payType.NPINo === this.Store.NPINo);
        this.showPayTypeArray = paytypeDataByNpi
          .filter(eachPay => eachPay.payTypeDesc !== null && eachPay.payTypeDesc !== undefined && eachPay.payType !== constant.CUSTOM_PAYMENT && eachPay.isHide === false)
          .map(eachPay => eachPay.payType);

      }
    });
  }

  onPayTypeSelectionChange(event: any): void {
    const customPayTypeSelected = event.some((payType: any) => payType.payType === 'CUSTOM_PAYMENT');

    // if (customPayTypeSelected) {
    //   this.customPayType = {
    //     NPINo: this.Store.NPINo,
    //     payType: 'CUSTOM_PAYMENT',
    //     payTypeDesc: 'Custom PayType',
    //   };
    // } else {
    //   //this.selectedPayTypeArray = this.payData.filter(payType => event.includes(payType.payType), payType => payType.NPINo == this.Store.NPINo);

    //   //this.showPayTypeArray=this.selectedPayTypeArray.filter(eachPay => eachPay.payType);
    // }
  }

  insertOrUpdateAllPayTypes(): void {
    let allPayTypesToInsert: any[] = this.mergeAllPayTypes();
    this.payTypeMasterService.InsertManyPayTypeMasterAsyncByNPI(allPayTypesToInsert).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          // this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_CREATE_TITLE, "Pay types updated successfully").subscribe(result => {

          // });
          console.log("Pay types updated successfully");
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {
          //this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
          console.log("Error while updating pay types");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  mergeAllPayTypes(): any[] {
    let allPayTypesToInsert: any[] = [];

    //Get all general pay types
    this.showPayTypeArray.forEach(selectedPayType => {
      let foundPayType = this.payTypes.find(item => item.payType === selectedPayType);

      let payTypeID: string | undefined;
      let payTypeDesc: string | undefined;
      let payType: string | undefined;
      if (foundPayType) {
        payTypeID = foundPayType.payTypeID.toString();
        payTypeDesc = foundPayType.payTypeDesc.toString();
        payType = foundPayType.payType.toString();
      } else {
        console.error('No matching payType found for:', selectedPayType);
      }
      const payTypeData = {
        NPINo: this.Store.NPINo,
        payTypeID: payTypeID,
        payTypeDesc: payTypeDesc,
        payType: payType,
        userID: sessionStorage.getItem('userName'),
        isHide: false,
        stopAtRefNo: ''
      };
      allPayTypesToInsert.push(payTypeData)
    });

    //Get custom pay types
    this.customPayTypes.forEach(selectedPayType => {

      const payTypeData = {
        NPINo: this.Store.NPINo,
        payTypeID: selectedPayType.payTypeID,
        payTypeDesc: selectedPayType.payTypeDesc,
        payType: selectedPayType.payType,
        userID: sessionStorage.getItem('userName'),
        isHide: false,
        stopAtRefNo: ''
      };
      allPayTypesToInsert.push(payTypeData)
    });

    return allPayTypesToInsert;
  }

  AllowAlphaNumericChar(value: any) {
    this.utilityService.AllowAlphaNumericChar(value);
  }

  CreatePharmacy(store: StoreRequest) {
    store.city = this.cityCtrl.value;
    //store.state = this.stateCtrl.value;

    const matchingState = this.stateArray.find(eachState => eachState.abbreviation === store.state);
    if (!matchingState) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select a valid state");
      return;
    }

    const matchingCity = this.cityArray.find(eachCity => eachCity.name === store.city);
    if (!matchingCity) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select a valid city");
      return;
    }

    if (store.state == undefined || store.state == "") {
      this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Please select state");
      return;
    } if (store.city == undefined || store.city == "") {
      this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Please select city");
      return;
    } else if ((store.isEdiv2 == undefined && store.isMpos == undefined && store.isPrimeRxCloud == undefined) || (store.isEdiv2 == false && store.isMpos == false && store.isPrimeRxCloud == false)) {
      this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Please select atleast one application");
      return;
    } else {
      store.selectedPayType = this.selectedPayTypemethod();

      this.mapToPrimeRxCloudStore(store)
        .pipe(
          takeUntil(this.destroy$),
          catchError(error => {
            console.error('Error in mapToPrimeRxCloudStore model:', error);
            this.destroy$.next();
            throw error;
          }),
          concatMap((mapToPrimeRxCloudStoreResult) => {
            // Create an array of observables based on slider values
            const observables: Observable<any>[] = [];

            //by default adding record to store configurato store service
            observables.push(this.storeService.Insert(store).pipe(
              map(result => ({ apiName: constant.STORE_CONFIG_STORE_SERVICE, result } as ApiResult))
            ));

            if (store.isMpos) {
              store.zipCode = store.zipCode.toString();
              observables.push(this.mposStoreService.Insert(store).pipe(
                map(result => ({ apiName: constant.POS_CLOUD_STORE_SERVICE, result } as ApiResult))
              ));

            }

            if (store.isPrimeRxCloud) {
              observables.push(this.primerxCloudPharmacyService.Insert(mapToPrimeRxCloudStoreResult).pipe(
                map(result => ({ apiName: constant.PRIMERRX_CLOUD_STORE_SERVICE, result } as ApiResult))
              ));
            }

            if (store.isEdiv2) {
              observables.push(this.ediStoreService.Insert(store).pipe(
                map(result => ({ apiName: constant.EDI_STORE_SERVICE, result } as ApiResult))
              ));
            }

            // Use forkJoin to combine the observables
            return forkJoin(observables);
          }),
          catchError(error => {
            console.log('Error in one of the API calls:', error);
            this.destroy$.next();
            throw error;
          })
        )
        .subscribe({
          next: (response) => {
            const apiResultsMap: { [key: string]: any } = {};

            response.forEach(apiResult => {
              apiResultsMap[apiResult.apiName] = apiResult.result;
            });

            const storeConfigResponse = apiResultsMap[constant.STORE_CONFIG_STORE_SERVICE];
            const ediStoreResponse = apiResultsMap[constant.EDI_STORE_SERVICE];
            const ePrimePOSStoreResponse = apiResultsMap[constant.POS_CLOUD_STORE_SERVICE];
            const primeRxCloudStoreResponse = apiResultsMap[constant.PRIMERRX_CLOUD_STORE_SERVICE];


            let storeConfigResponseMsg = "";
            let ediStoreResponseMsg = "";
            let ePrimePOSStoreResponseMsg = "";
            let primeRxCloudStoreResponseMsg = "";

            if (storeConfigResponse) {
              if (storeConfigResponse.result == constant.SUCCESS) {
                storeConfigResponseMsg = "Pharmacy added in Store Configurator \n";
                //#region #EPPOS-3788
                // Check if allowDefaultItems is true before calling createQuickAddItems
                if (store.allowDefaultItems) {
                  this.createQuickAddItems(store.NPINo, store.allowDefaultItems).subscribe({
                    next: () => {
                      console.log("Quick Add Items created successfully.");
                    },
                    error: (error) => {
                      console.error("Error while creating Quick Add Items:", error);
                      // Handle error appropriately if needed
                    }
                  });
                }
                //#endregion
              } else {
                storeConfigResponseMsg = storeConfigResponse.message;
              }
            }

            if (ediStoreResponse) {
              if (ediStoreResponse.result == constant.SUCCESS) {
                if (store.isEdiv2) ediStoreResponseMsg = "Pharmacy added in EDI Cloud\n";
              } else {
                ediStoreResponseMsg = ediStoreResponse.message;
              }
            }

            if (ePrimePOSStoreResponse) {
              if (ePrimePOSStoreResponse.result == constant.SUCCESS) {
                if (store.isMpos) ePrimePOSStoreResponseMsg = "Pharmacy added in PrimeRx POS \n";
              } else {
                ePrimePOSStoreResponseMsg = ePrimePOSStoreResponse.message;
              }
            }

            if (primeRxCloudStoreResponse) {
              if (primeRxCloudStoreResponse.result == constant.SUCCESS) {

                if (store.isPrimeRxCloud) primeRxCloudStoreResponseMsg = "Pharmacy added in PrimeRx Cloud \n";

              } else {
                primeRxCloudStoreResponseMsg = primeRxCloudStoreResponse.message;
              }
            }

            this.insertOrUpdateAllPayTypes();

            var overAllMsg = storeConfigResponseMsg + ediStoreResponseMsg + ePrimePOSStoreResponseMsg + primeRxCloudStoreResponseMsg;
            this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, overAllMsg).subscribe(result => {
              //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
              this.dialogService.close(true);
            });

          },
          error: (errorResponse) => {
            console.log(errorResponse);
            //HERE CALLING THE ROLLBACK API IN CASE STORE CREATION FAILED IN ANY OF THE STORE
            this.RollBackStoreCreated(store).subscribe({
              next: (result) => {
                // handle the result
                this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
              },
              error: (error) => {
                console.log('RollBackStoreCreated error:', error);
                // handle the error
                this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, error);
              }
            });
          }
        });
    }

  }

  UpdateStore(store: StoreRequest) {
    if (store.isMpos) {
      const mposStore = this.fullStoreData.find(store => store.NPINo = store.NPINo)
      const stationDetails = mposStore?.StationDetail;

      if (stationDetails != undefined) {
        if (stationDetails!.length > Number(this.Store.terminal)) {
          this.utilityService.showAlert("WARNING", "Warning", "Can not change number of terminals. \n" + stationDetails.length + " stations have been already added.");
          return;
        }
      }

    }
    //const matchingObject = this.fullStoreData.retstore.find(item => item.NPINo === npiToFind);

    this.message = '';
    store.city = this.cityCtrl.value;
    //store.state = this.stateCtrl.value;
    const matchingState = this.stateArray.find(eachState => eachState.abbreviation === store.state);

    if (!matchingState) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select a valid state");
      return;
    }

    const matchingCity = this.cityArray.find(eachCity => eachCity.name === store.city);
    if (!matchingCity) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select a valid city");
      return;
    }

    if (store.state == undefined || store.state == "") {
      this.utilityService.showAlert("WARNING", "Warning", "Please select state");
      return;
    } if (store.city == undefined || store.city == "") {
      this.utilityService.showAlert("WARNING", "Warning", "Please select city");
      return;
    } else if ((store.isEdiv2 == undefined && store.isMpos == undefined && store.isPrimeRxCloud == undefined) || (store.isEdiv2 == false && store.isMpos == false && store.isPrimeRxCloud == false)) {

      this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Please select atleast one application");
      return;

    } else {

      store.updatedBy = sessionStorage.getItem('userName');
      store.updatedDate = new Date();

      let hasDuplicates: boolean = false;
      if (this.customPayTypes.length > 0) {
        hasDuplicates = this.customPayTypes.filter((item, index) =>
          this.customPayTypes.some((other, otherIndex) => otherIndex !== index && other.payTypeDesc === item.payTypeDesc)
        ).length > 0;
      }


      if (hasDuplicates) {
        this.utilityService.showAlert(constant.FAILURE, "Failed", "Duplicate Custom Pay Type Description");
        return;
      }

      this.storeService.Update(store).subscribe({
        next: (response) => {
          let data = JSON.parse(response.data);

          var storeData = {
            NPINo: store.NPINo
          }

          var storeAdmin = {};
          if (response.result == constant.SUCCESS) {
            //#region #EPPOS-3788
            // Check if allowDefaultItems is true before calling createQuickAddItems
            this.createQuickAddItems(store.NPINo, store.allowDefaultItems).subscribe({
              next: () => {
                console.log("Quick Add Items created successfully.");
              },
              error: (error) => {
                console.error("Error while creating Quick Add Items:", error);
                // Handle error appropriately if needed
              }
            });
            //#endregion

            this.message = "Pharmacy updated in Store Configurator";

            let getExistingUserForStore = this.storeAdminService.GetStoreAdminByNPI(storeData);

            forkJoin([getExistingUserForStore]).subscribe(responses => {

              if (responses[0].result == constant.SUCCESS) {
                storeAdmin = JSON.parse(responses[0].data);

                var str = {
                  store: store,
                  user: storeAdmin
                }

                let ediUpdateStore = this.ediStoreService.Update(str);
                let mposUpdateStore = this.mposStoreService.Update(str);
                let deleteAccessEdiStore = this.ediStoreService.DeleteAccess(store);
                let deleteAccessMPOSStore = this.mposStoreService.DeleteAccess(store);
                if (store.isEdiv2) {
                  forkJoin([ediUpdateStore]).subscribe(responses => {
                    console.log('edi repsonse');

                    if (responses[0].result == constant.SUCCESS) {

                      if (store.isEdiv2) this.message = this.message + "\n" + "Pharmacy updated in EDI Cloud";

                      if (store.isMpos) {
                        forkJoin([mposUpdateStore]).subscribe(responses => {
                          console.log('mpos repsonse');

                          if (responses[0].result == constant.SUCCESS) {


                            if (store.isMpos) this.message = this.message + "\n" + "Pharmacy updated in PrimeRx POS";
                            this.utilityService.showAlert(constant.SUCCESS, "Updated", this.message).subscribe(result => {
                              //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                              this.dialogService.close(true);
                            });
                          } else {

                            if (store.isMpos) this.message = this.message + "\n" + "Error in updating Pharmacy in PrimeRx POS";

                            this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                          }
                        });
                      } else {
                        forkJoin([deleteAccessMPOSStore]).subscribe(responses => {
                          console.log('mpos repsonse');


                          if (responses[0].result == constant.SUCCESS) {


                            if (store.isMpos) this.message = this.message + "\n" + "Pharmacy updated in PrimeRx POS";
                            this.utilityService.showAlert(constant.SUCCESS, "Updated", this.message).subscribe(result => {
                              //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                              this.dialogService.close(true);
                            });
                          } else {

                            if (store.isMpos) this.message = this.message + "\n" + "Error in updating Pharmacy in PrimeRx POS";

                            this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                          }
                        });
                      }
                    } else {

                      this.message = this.message + "\n" + "Error in updating Pharmacy in EDI Cloud";

                      if (store.isMpos) {
                        forkJoin([mposUpdateStore]).subscribe(responses => {
                          console.log('mpos repsonse');

                          if (responses[0].result == constant.SUCCESS) {

                            if (store.isMpos) this.message = this.message + "\n" + "Pharmacy updated in PrimeRx POS";
                            this.utilityService.showAlert(constant.SUCCESS, "Updated", this.message).subscribe(result => {
                              //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                              this.dialogService.close(true);
                            });
                          } else {

                            if (store.isMpos) this.message = this.message + "\n" + "Error In Updating Pharmacy In PrimeRx POS";

                            this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                          }
                        });
                      } else {
                        forkJoin([deleteAccessMPOSStore]).subscribe(responses => {
                          console.log('mpos repsonse');


                          if (responses[0].result == constant.SUCCESS) {
                            if (store.isMpos) this.message = this.message + "\n" + "Pharmacy updated in PrimeRx POS";
                            this.utilityService.showAlert(constant.SUCCESS, "Updated", this.message).subscribe(result => {
                              //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                              this.dialogService.close(true);
                            });
                          } else {

                            if (store.isMpos) this.message = this.message + "\n" + "Error in updating Pharmacy in PrimeRx POS";

                            this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                          }
                        });
                      }
                    }
                  });
                } else {
                  forkJoin([deleteAccessEdiStore]).subscribe(responses => {
                    console.log('mpos repsonse');


                    if (responses[0].result == constant.SUCCESS) {


                      if (store.isEdiv2) this.message = this.message + "\n" + "Pharmacy updated in EDI Cloud";
                      // this.utilityService.showAlert(constant.SUCCESS, "Updated", this.message).subscribe(result => {
                      //   //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                      //   this.dialogService.close(true);
                      // });
                    } else {

                      if (store.isEdiv2) this.message = this.message + "\n" + "Error in updating Pharmacy in EDI Cloud";

                      this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                    }
                  });

                  if (store.isMpos) {
                    forkJoin([mposUpdateStore]).subscribe(responses => {
                      console.log('mpos repsonse');


                      if (responses[0].result == constant.SUCCESS) {


                        if (store.isMpos) this.message = this.message + "\n" + "Pharmacy updated in PrimeRx POS";
                        this.utilityService.showAlert(constant.SUCCESS, "Updated", this.message).subscribe(result => {
                          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                          this.dialogService.close(true);
                        });
                      } else {

                        if (store.isMpos) this.message = this.message + "\n" + "Error in updating Pharmacy in PrimeRx POS";
                        this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                      }
                    });
                  } else {
                    forkJoin([deleteAccessMPOSStore]).subscribe(responses => {
                      console.log('mpos repsonse');


                      if (responses[0].result == constant.SUCCESS) {


                        if (store.isMpos) this.message = this.message + "\n" + "Pharmacy updated in PrimeRx POS";
                        this.utilityService.showAlert(constant.SUCCESS, "Updated", this.message).subscribe(result => {
                          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                          this.dialogService.close(true);
                        });
                      } else {

                        if (store.isMpos) this.message = this.message + "\n" + "Error in updating Pharmacy in PrimeRx POS";

                        this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                      }
                    });
                  }

                }

              }
            });

            //update pay types
            this.insertOrUpdateAllPayTypes();

          } else {
            this.utilityService.showAlert(constant.FAILURE, "Failed",
              "Error in updating Pharmacy in Store Configurator because " + data.result);
          }
        },
        error: (errorResponse) => {
          console.log(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
        }
      });
    }
  }

  DeleteStore(store: StoreRequest) {
    const passData = {
      promptTitle: "Are you sure?",
      promptMessage: "You won't be able to revert this!",
    };

    if (store.isStoreAdminCreated)
    {
      passData.promptMessage= "You won't be able to revert this!\n\nThis pharmacy has an associated admin. Deleting the pharmacy will deactivate the pharmacy admin. Do you want to proceed?";
    }
      
    this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
      if (result === true) {
        try {
          const userName = sessionStorage.getItem('userName');
          store.updatedBy = userName;
          store.updatedDate = new Date();
          store.isDelete = true;
  
          const user = {
            NPINo: store.NPINo || '',
          };
  
          // Check for valid store data
          if (!store.NPINo) {
            this.utilityService.showAlert(constant.FAILURE, "Failed", "Invalid store information provided.");
            return;
          }
  
          this.storeService.Delete(store).subscribe({
            next: response => {
              const data = response?.data ? JSON.parse(response.data) : null;
              if (!data || response.result !== constant.SUCCESS) {
                this.utilityService.showAlert(constant.FAILURE, "Failed", `Error in deleting Pharmacy: ${data?.result || 'Unknown error'}`);
                return;
              }
  
              this.message = "Pharmacy deleted in Store Configurator";
  
              // Prepare deletion observables with error handling
              const adminDeletions = [
                { name: 'Store Admin', observable: this.storeAdminService.Delete(user).pipe(catchError(err => of({ error: err }))) },
                { name: 'EDI Admin', observable: this.EDIstoreAdminService.Delete(user).pipe(catchError(err => of({ error: err }))) },
                { name: 'MPOS Admin', observable: this.MPOSstoreAdminService.Delete(user).pipe(catchError(err => of({ error: err }))) },
              ];
  
              forkJoin(adminDeletions.map(ad => ad.observable)).subscribe({
                next: results => {
                  const adminErrors = results.filter(res => res.error); // Collect errors only
                  if (adminErrors.length > 0) {
                    adminErrors.forEach(error => {
                      const failedAdmin = adminDeletions.find(ad => ad.observable === error)?.name;
                      //if (failedAdmin) {
                        this.message += `\nFailed deleting user. Please go to the Pharmacy Admins page to manually remove the user.`;
                      //}
                    });
  
                    // this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                    // return;
                  }
  
                  console.log('Admin deletions completed');
                  const ediDeleteStore = store.isEdiv2 ? this.ediStoreService.Delete(store) : null;
                  const mposDeleteStore = store.isMpos ? this.mposStoreService.Delete(store) : null;
  
                  forkJoin([ediDeleteStore, mposDeleteStore].filter(Boolean)).subscribe({
                    next: responses => {
                      responses.forEach((res, index) => {
                        if (res?.result === constant.SUCCESS) {
                          if (index === 1) this.message += "\nPharmacy deleted in PrimeRx POS";
                        } else {
                          const errorContext = 'PrimeRx POS';
                          this.message += `\nError in deleting Pharmacy from ${errorContext}`;
                        }
                      });
  
                      this.utilityService.showAlert(constant.SUCCESS, "Deleted", this.message).subscribe(() => {
                        this.dialogService.close(true);
                      });
                    },
                    error: err => {
                      console.error('Error during EDI or MPOS deletion:', err);
                      this.utilityService.showAlert(constant.FAILURE, "Failed", "Error occurred during external deletions.");
                    }
                  });
                },
                error: err => {
                  console.error('Unexpected error during admin deletions:', err);
                  this.utilityService.showAlert(constant.FAILURE, "Failed", "An unexpected error occurred during admin deletions.");
                }
              });
            },
            error: err => {
              console.error('Store deletion error:', err);
              this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Error occurred during store deletion.");
            }
          });
        } catch (err) {
          console.error('Unexpected error:', err);
          this.utilityService.showAlert(constant.FAILURE, "Failed", "An unexpected error occurred.");
        }
      } else if (result?.isDenied) {
        console.log('User denied deletion confirmation.');
      }
    });
  }
  
  
  
  

  

  //#region #EPPOS-3788
  createQuickAddItems(npiNo: string, allowDefaultItems: boolean): Observable<any> {
    let defaultItems: any = {
      NPINo: npiNo,
      createdBy: sessionStorage.getItem('userName'),
      updatedBy: sessionStorage.getItem('userName'),
      AllowDefaultItems: allowDefaultItems
    };

    // Insert default items and handle response
    return this.itemService.insertDefaultItem(defaultItems).pipe(
      concatMap(response => {
        if (response.data) {
          return of(response.data); // Just pass the data forward without showing an alert
        } else {
          return throwError(() => new Error("Error while inserting default items"));
        }
      }),
      catchError(error => {
        console.error("Error in createQuickAddItems:", error);
        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error while inserting/updating default items");
        return throwError(() => error);
      })
    );
  }
  //#endregion

  getOrganizations() {

    this.organizationReq.pagination.pageNumber = 1;
    this.organizationReq.pagination.pageSize = 200;

    this.organizationService.GetOrganizationByPagination(this.organizationReq).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.organizationArray = response.data.organizations;

          if (this.organizationArray.length < 1) {

            this.utilityService.showAlert('ERROR', 'Failed', 'No Organizatio Data Found');
          }
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }


  getMasterDB() {

    this.primerxCloudPharmacyService.GetMasterDBName().subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {


          if (environment.baseURL.includes("prod")) {
            this.masterDBArray.push({ name: 'ePrimeRx_MMS_Prod1' });
          } else {
            this.masterDBArray = response.data;

            if (this.masterDBArray.length < 1) {

              this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, 'No Master DB Found');
            }
          }

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });

  }

  mapToPrimeRxCloudStore(storeData: StoreRequest): Observable<PharmacyCreateRequest> {
    console.log("Mapping the pharmacy data to primerx cloud compatible fields");
    if (this.Store.isPrimeRxCloud) {
      const city = this.cityArray.find(eachCity => eachCity.name == storeData.city);
      const state = this.cityArray.find(eachCity => eachCity.name == storeData.city);

      this.pharmacyCreateRequest = {
        organizationId: storeData.organizationId,
        adminFirstName: storeData.primeRxCloudData.adminFirstName,
        adminLastName: storeData.primeRxCloudData.adminLastName,
        adminUserName: storeData.primeRxCloudData.adminUserName,
        adminPassword: storeData.primeRxCloudData.adminPassword,
        pharmacyEmail: storeData.email,
        pharmacyPhoneNumber: storeData.contactNo,
        contactPerson: storeData.contactName,
        pharmacyFaxNumber: storeData.fax,
        rxGenSys: 1,
        pharmacyAddressLine1: storeData.address1,
        pharmacyAddressLine2: storeData.address2,
        masterDbName: storeData.primeRxCloudData.masterDbName,
        pharmacyNpiNumber: storeData.NPINo,
        pharmacyZipCode: storeData.zipCode,
        pharmacyNABPNum: storeData.primeRxCloudData.pharmacyNABPNum,
        PharmacyStateCode: storeData.state,
        pharmacyCityId: city.id,
        pharmacyRegistrationNum: storeData.primeRxCloudData.pharmacyRegistrationNum,
        pharmacyDEARegistration: storeData.primeRxCloudData.pharmacyDEARegistration,
        pharmacyName: storeData.storeName,
        isPrimeRxCloudEmptyPharmacy: storeData.isPrimeRxCloudEmptyPharmacy

      };
    }

    return of(this.pharmacyCreateRequest);

  }


  goToELO() {
    window.open(" https://manage.eloview.com/", "_blank");
  }

  AllowOnlyNumber(event: any) {

    this.utilityService.AllowOnlyNumber(event);
  }


  onPasswordStrengthChanged(event: any) {
    if (event == 100) {
      this.isPasswordStrong = true;
    } else {
      this.isPasswordStrong = false;
    }

  } 

  //EPPOS-3847
  onKeydown(event: KeyboardEvent): void {
    const key = event.key.toLowerCase();
    // Allow only one valid alphaNumric character as input
    if (key.length === 1 && /^[a-zA-Z0-9]$/.test(key)) {
      this.searchInput = key; // Replace the previous input with the current key
    } 
    //sort the organizations based on the single letter
    this.sortOrganizations();
  }
  
  sortOrganizations(): void {
    this.filteredOrganizations = this.organizationArray.sort((a, b) => {
      const searchLower = this.searchInput.toLowerCase();
      const nameALower = a.name.toLowerCase();
      const nameBLower = b.name.toLowerCase();
  
      // Check if both names start with the search input
      const startsWithA = nameALower.startsWith(searchLower);
      const startsWithB = nameBLower.startsWith(searchLower);
  
      // Extract numeric parts from the start of the string for both names
      const numA = parseFloat(nameALower.match(/^\d+/)?.[0] || "NaN");
      const numB = parseFloat(nameBLower.match(/^\d+/)?.[0] || "NaN");
  
      // If both organizations start with the search input, compare based on text or numbers
      if (startsWithA === startsWithB) {
        // If both names have numeric prefixes, compare them numerically
        if (!isNaN(numA) && !isNaN(numB)) {
          return numA - numB;
        }
  
        // If one name has a numeric prefix and the other does not, prioritize the numeric one
        if (!isNaN(numA)) {
          return -1;
        }
        if (!isNaN(numB)) {
          return 1;
        }
  
        // If no numeric prefix, fall back to alphabetical sorting
        return nameALower.localeCompare(nameBLower);
      }
  
      // Prioritize organizations starting with the search input
      return startsWithA ? -1 : 1;
    });
  } //EPPOS-3847
  

  onOrganizationChange(organizationId: any) {
    const selectedOrg = this.organizationArray.find(org => org.organizationId == organizationId);

    this.Store.organizationName = selectedOrg.name;
  }

  updateCustomPay(pay: any) {
    const containsTest: boolean = this.customPayTypes.some(p => p.payTypeDesc === pay.payTypeDesc);

    if (containsTest == false) {
      this.payTypeMasterService.UpdatePayTypeMasterAsyncByNPI(pay).subscribe({
        next: (response) => {

          this.getPayTypes();


        },
        error: (errorResponse) => {
          console.log(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
        }
      });
    }
    else {
      this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Pay type with description '" + pay.payTypeDesc + "' already exists");
    }

  }

  openPayTypeMasterModal() {
    const paytypeDialogRef = this.dialogPayService.open(PayTypeModal, {
      width: '21%',
      disableClose: false
    });


    paytypeDialogRef.afterClosed().subscribe(result => {
      let insertSuccess: boolean = false;
      if (result) {

        if (this.Store.NPINo == undefined || this.Store.NPINo === '') {
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Please enter NPI and other details of the Pharmacy");
        }
        else {
          let payTypeData = {
            NPINo: this.Store.NPINo,
            payTypeID: '',
            payTypeDesc: result.payTypeDesc,
            payType: constant.CUSTOM_PAYMENT,
            UserID: sessionStorage.getItem('userName')
          };
          const existingPayTypeIndex = this.customPayTypes.findIndex(payType => payType.payTypeDesc === result.payTypeDesc);
          if (existingPayTypeIndex !== -1) {
            this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Pay Type Alrady Exists");

          }
          else {
            this.storePayTypeArray.push(payTypeData);
            this.customPayTypes.push(payTypeData);
          }

        }

      }
      if (insertSuccess)
        this.utilityService.showAlert(constant.SUCCESS, constant.ALERT_SUCCESS_TITLE, "Pay Type Inserted Successfully");
    });
  }


  //#region DROPDOWN STATE CITY

  IntializeStateCtrl() {

    if (this.Store && this.Store.state) {
      const stateCode = this.stateArray.find(eachState => eachState.abbreviation == this.Store.state)
      this.stateCtrl = new FormControl(stateCode.stateName);
    } else {

      this.stateCtrl = new FormControl();
    }

    if (!this.stateCtrl.value) {
      this.stateCtrl.setValue("");
    }

    this.filteredState = this.stateCtrl.valueChanges.pipe(
      startWith(this.stateCtrl.value),
      map((state) =>
        state ? this.filterState(state) : this.stateArray.slice()
      )
    );
  }
  IntializeCityCtrl() {

    if (this.Store && this.Store.city) {
      this.cityCtrl = new FormControl(this.Store.city);
    } else {

      this.cityCtrl = new FormControl();
    }

    if (!this.cityCtrl.value) {
      this.cityCtrl.setValue("");
    }

    this.filteredCity = this.cityCtrl.valueChanges.pipe(
      startWith(this.cityCtrl.value),
      map((city) =>
        city ? this.filterCity(city) : this.cityArray.slice()
      )
    );
  }

  filterState(name: string) {
    let arr = this.stateArray.filter(
      (state) => state.stateName.toLowerCase().indexOf(name.toLowerCase()) === 0
    );

    return arr.length ? arr : [{ stateName: 'No Matching State Found', abbreviation: 'null' }];
  }

  filterCity(name: string) {
    let arr = this.cityArray.filter(
      (city) => city.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );

    return arr.length ? arr : [{ name: 'No Matching City Found', id: 0 }];
  }


  ClearState() {
    this.stateCtrl.setValue("");
    this.Store.state = this.stateCtrl.value;

    this.ClearCity();
  }

  ClearNotMatchingState() {

    const stateName = this.stateCtrl.value;
    const selctedState = this.stateArray.find(eachState => eachState.stateName == stateName);
    if (selctedState == undefined || selctedState == "" || selctedState == null) {
      this.stateCtrl.setValue("");
      this.Store.state = this.stateCtrl.value;
    }
  }

  ClearCity() {
    this.cityCtrl.setValue("");
    this.Store.city = this.cityCtrl.value;
  }

  ClearNotMatchingCity() {

    const cityName = this.cityCtrl.value;
    const selctedCity = this.cityArray.find(eachCity => eachCity.name == cityName);
    if (selctedCity == undefined || selctedCity == "" || selctedCity == null) {
      this.cityCtrl.setValue("");
      this.Store.city = this.cityCtrl.value;
    }
  }

  loadCityForCurrentState(stateCode: string) {

    const stateName = this.stateCtrl.value;
    const selctedState = this.stateArray.find(eachState => eachState.stateName == stateName);
    const cityRequest = {
      stateCode: selctedState.abbreviation
    }

    //update the value of store model
    this.Store.state = selctedState.abbreviation;

    this.primerxCloudPharmacyService.loadCityForCurrentState(cityRequest).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.cityArray = response.data;

          this.IntializeCityCtrl();

          if (this.cityArray.length < 1) {

            this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, 'No City found');
          }
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });

  }

  OnCitySelection() {

    this.Store.city = this.cityCtrl.value;
  }


  //#endregion


  checkShortNameExists() {

    if (this.Store != undefined && this.Store.pseudoName != undefined && this.Store.pseudoName != "" && this.Store.pseudoName != null) {


      this.storeService.CheckShortNameExists(this.Store).subscribe({
        next: (response) => {
          if (response.result == ResponseStatus.SUCCESS) {


          } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

            this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, response.message);
            this.Store.pseudoName = "";
          }
        },
        error: (errorResponse) => {
          console.log(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
        }
      });
    }
  }

  checkNPINoExists() {

    if (this.Store != undefined && this.Store.NPINo != undefined && this.Store.NPINo != "" && this.Store.NPINo != null) {


      this.storeService.checkNPINoExists(this.Store).subscribe({
        next: (response) => {
          if (response.result == ResponseStatus.SUCCESS) {


          } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

            this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, response.message);
            this.Store.NPINo = "";
          }
        },
        error: (errorResponse) => {
          console.log(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
        }
      });

    }
  }

  RollBackStoreCreated(store: any): Observable<any> {
    const observables: Observable<any>[] = [];

    // by default adding record to store configuration store service
    observables.push(this.storeService.RollBackStore(store).pipe(
      map(result => ({ apiName: constant.STORE_CONFIG_STORE_SERVICE, result } as ApiResult))
    ));

    if (store.isMpos) {

      observables.push(this.mposStoreService.RollBackStore(store).pipe(
        map(result => ({ apiName: constant.POS_CLOUD_STORE_SERVICE, result } as ApiResult))
      ));
    }

    if (store.isEdiv2) {
      observables.push(this.ediStoreService.RollBackStore(store).pipe(
        map(result => ({ apiName: constant.EDI_STORE_SERVICE, result } as ApiResult))
      ));
    }

    return forkJoin(observables).pipe(
      map(response => {
        const apiResultsMap: { [key: string]: any } = {};

        response.forEach(apiResult => {
          apiResultsMap[apiResult.apiName] = apiResult.result;
        });

        const storeConfigResponse = apiResultsMap[constant.STORE_CONFIG_STORE_SERVICE];
        const ediStoreResponse = apiResultsMap[constant.EDI_STORE_SERVICE];
        const ePrimePOSStoreResponse = apiResultsMap[constant.POS_CLOUD_STORE_SERVICE];

        let storeConfigResponseMsg = "";
        let ediStoreResponseMsg = "";
        let ePrimePOSStoreResponseMsg = "";

        if (storeConfigResponse) {
          if (storeConfigResponse.result === constant.SUCCESS) {
            storeConfigResponseMsg = "Pharmacy deleted in Admin console";
          } else {
            storeConfigResponseMsg = storeConfigResponse.message;
          }
        }

        if (ediStoreResponse) {
          if (ediStoreResponse.result === constant.SUCCESS) {
            if (store.isEdiv2) ediStoreResponseMsg = "Pharmacy deleted in EDI";
          } else {
            ediStoreResponseMsg = ediStoreResponse.message;
          }
        }

        if (ePrimePOSStoreResponse) {
          if (ePrimePOSStoreResponse.result === constant.SUCCESS) {
            if (store.isMpos) ePrimePOSStoreResponseMsg = "Pharmacy deleted in PrimeRx POS";
          } else {
            ePrimePOSStoreResponseMsg = ePrimePOSStoreResponse.message;
          }
        }

        const overAllMsg = `${storeConfigResponseMsg}\n${ediStoreResponseMsg}\n${ePrimePOSStoreResponseMsg}`;

        return true; // return the final message or any other data
      }),
      catchError(errorResponse => {
        console.log(errorResponse);
        // handle the error and rethrow if necessary
        return throwError(() => new Error(errorResponse));
      })
    );
  }

  givePromptForEmptyPharmacy() {

    if (this.Store.isPrimeRxCloudEmptyPharmacy) {

      let passData = {
        promptTitle: "Are you sure ?",
        promptMessage: "By checking this checkbox you will crete an empty pharmacy in Primerx cloud  and you won't be able to revert this!",
      };

      this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
        if (result == true) {
          this.Store.isPrimeRxCloudEmptyPharmacy = true;

        } else {
          this.Store.isPrimeRxCloudEmptyPharmacy = false;
        }
      });
    }
  }

  setDefaultAdminForPrimerx(){
    if(this.Store.isPrimeRxCloud == true) {
      this.Store.primeRxCloudData.adminLastName=""

    } else {
      this.Store.isPrimeRxCloudEmptyPharmacy = false;
    }
  }

  ngAfterViewInit(): void {
  }

  public isFormInvalid(): boolean {
    var res = false;
    // Check if storeModalForm is defined before accessing properties
    if (!this.storeModalForm) {
      res = false; // Or true, depending on your logic
    }
    else {

      res = this.storeModalForm.invalid
        || (this.Store.isMpos && this.payTypes.length > 0 && this.showPayTypeArray.length == 0)
        || (this.customPayTypes.length > 0 && this.customPayTypes.every(payType => payType.payTypeDesc && payType.payTypeDesc.toString().trim().length == 0));
    }
    return res;
  }
}

@Component({
  selector: 'pay-type-modal',
  templateUrl: 'pay-type-modal.html',
  styleUrls: ['./store-modal.component.css']
})

export class PayTypeModal implements OnInit {

  payTypeData: any = {};

  ngOnInit(): void {
    // Initialization logic if needed
  }

  constructor() { }
}
