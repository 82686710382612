import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { OTCBenifits } from 'src/app/models/OTCBenifits';
import { OrganizationRequest } from 'src/app/models/Organization/OrganizationRequest';
import { OTCBenifitsSearchRequest } from 'src/app/models/RequestModel/OTCBenifitsSearchRequest';
import { OrganizationSearchRequest } from 'src/app/models/RequestModel/OrganizationSearchRequest';
import { NBSProcessorService } from 'src/app/services/nbsprocessor.service';
import { UtilityService } from 'src/app/services/utility.service';
import { OrganizationModalComponent } from '../organization/organization-modal/organization-modal.component';
import { OTCBenifitsModalComponent } from './otcbenifitsModal/otcbenifits-modal.component';

@Component({
  selector: 'app-otcbenifits',
  templateUrl: './otcbenifits.component.html',
  styleUrls: ['./otcbenifits.component.css']
})
export class OTCBenifitsComponent implements OnInit {

  NPINo: string = '';
  currentPage = 0;
  pageSize = 10;
  totalRows = 0;

  displayedColumns = ['NPINo', 'StoreName','AppId', 'Subscription','IsActive', 'Action'];
  pageSizeOptions = [10, 20, 30];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<OTCBenifits>;
  OtcBenifitsArray: any = [];
  otcBenifitsSearch: OTCBenifitsSearchRequest = new OTCBenifitsSearchRequest;

  OtcBenifits: any = {};
  calledFromSearch: boolean = false;

  constructor(public NBSService: NBSProcessorService, private utilityService: UtilityService, private dialogService: MatDialog,) { }

  ngOnInit(): void {
    this.GetOtcBenifits();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.GetOtcBenifits();
    });
  }

  SearchOtcBenifits() {

    this.NBSService.SearchOtcBenifits(this.otcBenifitsSearch).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          const apiResponse = JSON.parse(response.data);
          this.OtcBenifitsArray = apiResponse.otcBenifitsStores;

          if (this.OtcBenifitsArray.length > 0) {
            this.dataSource = new MatTableDataSource<OTCBenifits>(this.OtcBenifitsArray);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            setTimeout(() => {
              this.paginator!.pageIndex = this.currentPage;
              this.paginator!.length = apiResponse.totalRecords;
            });

          } else {
            this.dataSource = new MatTableDataSource<OTCBenifits>(this.OtcBenifitsArray);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, 'No Data Found');
          }
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {
          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  GetOtcBenifits() {

    this.otcBenifitsSearch.pagination.pageNumber = this.currentPage + 1;
    this.otcBenifitsSearch.pagination.pageSize = this.pageSize;

    this.NBSService.GetOtcBenifits(this.otcBenifitsSearch).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          const apiResponse = JSON.parse(response.data);
          this.OtcBenifitsArray = apiResponse.otcBenifitsStores;

          if (this.OtcBenifitsArray.length > 0) {
            this.dataSource = new MatTableDataSource<OTCBenifits>(this.OtcBenifitsArray);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            setTimeout(() => {
              this.paginator!.pageIndex = this.currentPage;
              this.paginator!.length = apiResponse.totalRecords;
            });

          } else {
            this.dataSource = new MatTableDataSource<OTCBenifits>(this.OtcBenifitsArray);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, 'No Data Found');
          }
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {
          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  OpenUpdateOtcBenifitsModal(passOTCBenifitData: any) {
    const otcBenifitsUpdateDialogRef = this.dialogService.open(OTCBenifitsModalComponent, {
      width: '50%',
      disableClose: false,
      data: {
        OTCBenifitsData: passOTCBenifitData,
        headerTitle: 'Update OTC Benefits'
      }
    });

    otcBenifitsUpdateDialogRef.afterClosed().subscribe(result => {
      this.GetOtcBenifits();
    });
  }

  OpenCreateOtcBenifitsModal(): void {
    const otcBenifitsCreateDialogRef = this.dialogService.open(OTCBenifitsModalComponent, {
      width: '50%',
      disableClose: false,
      data: {
        headerTitle: 'Add OTC Benefits'
      },
    });

    otcBenifitsCreateDialogRef.afterClosed().subscribe(result => {
      this.GetOtcBenifits();
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.GetOtcBenifits();
  }

  keyPressAlphaNumericWithCharacters(event: any) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
