import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { EDIStoreAdminService } from 'src/app/services/edi_storeAdmin.service';
import { MPOSStoreAdminService } from 'src/app/services/mpos_storeAdmin.service';
import { StoreService } from 'src/app/services/store.service';
import { StoreAdminService } from 'src/app/services/storeAdmin.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-store-admin-modal',
  templateUrl: './store-admin-modal.component.html',
  styleUrls: ['./store-admin-modal.component.css']
})
export class StoreAdminModalComponent implements OnInit {

  @Input() modal_title: any;
  @ViewChild('closebutton')
  closebutton!: { nativeElement: { click: () => void; }; };

  state: any = [];

  constructor(@Inject(MAT_DIALOG_DATA) public receivedData: any, private http: HttpClient,
    private storeAdminService: StoreAdminService, private router: Router, private EDIstoreAdminService: EDIStoreAdminService
    , private MPOSstoreAdminService: MPOSStoreAdminService, private utilityService: UtilityService,
    private storeService: StoreService, private dialogService: MatDialogRef<StoreAdminModalComponent>) { }
  Store: any = {};
  StoreAdmin: any = {};
  message: any = "";
  storeData: any = {};
  userName: any = '';
  pseudoName: any = '';
  hide: boolean = true;
  hideConfirm: boolean = true;
  showDetails: boolean = true;
  showError: boolean = false;
  panelOpenState: boolean = false;
  isPasswordStrong: boolean = false;
  primeRxPOSAdmin: any = {};

  ngOnInit(): void {
    if (this.receivedData.userData != undefined) {
      this.Store = this.receivedData.userData;

      this.Store.isEnabled = true;
      this.storeData.userName = '';
      // this.Store.password = '';
      if (this.Store.userName != null) {
        var name = this.Store.userName.split("@");
        if (name.length == 2) {
          this.storeData.userName = name[0];
          this.pseudoName = name[1];
        }
      } else {
        this.pseudoName = this.Store.pseudoName;

      }

      this.getPrimeRxPOSAdminByUserName(this.Store.userName);
    }

  }

  onPasswordStrengthChanged(event: any) {
    if (event == 100) {
      this.isPasswordStrong = true;
    } else {
      this.isPasswordStrong = false;
    }

  }

  InsertStoreAdmin(user: any) {

    //DELETING PRIMERX FIELD DATA FROM THE MODEL
    delete user.migrationDetails;
    user.modifiedBy = sessionStorage.getItem('userName');
    user.modifiedOn = new Date();

    user.userName = this.storeData.userName + '@' + this.pseudoName;

    this.storeAdminService.Insert(user).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.message = "Pharmacy Admin added in Store Configurator";
          let ediCreateStoreAdmin = this.EDIstoreAdminService.Insert(user);
          let mposCreateStoreAdmin = this.MPOSstoreAdminService.Insert(user);

          if (user.isEdiv2) {
            forkJoin([ediCreateStoreAdmin]).subscribe(responses => {
              console.log('edi repsonse');

              if (responses[0].result == constant.SUCCESS) {
                this.message = this.message + "\n" + "Pharmacy Admin added in EDI Cloud";
                if (user.isMpos) {
                  forkJoin([mposCreateStoreAdmin]).subscribe(responses => {
                    console.log('mpos repsonse');

                    if (responses[0].result == constant.SUCCESS) {
                      this.router.navigateByUrl('/storeAdmin');


                      this.message = this.message + "\n" + "Pharmacy Admin added in PrimeRx POS";
                      this.utilityService.showAlert(constant.SUCCESS, "Created", this.message);
                    } else {

                      this.message = this.message + "\n" + "Error in adding Pharmacy Admin in PrimeRx POS";
                      this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                    }
                  });
                } else {
                  this.router.navigateByUrl('/storeAdmin');


                  this.utilityService.showAlert(constant.SUCCESS, "Created", this.message);
                }
              } else {
                //this.message = this.message + "\n" + "Error in adding Pharmacy Admin in EDI CLOUD";
                if (user.isMpos) {
                  forkJoin([mposCreateStoreAdmin]).subscribe(responses => {
                    console.log('mpos repsonse');

                    if (responses[0].result == constant.SUCCESS) {
                      this.router.navigateByUrl('/storeAdmin');


                      this.message = this.message + "\n" + "Pharmacy Admin added in PrimeRx POS";
                      this.utilityService.showAlert(constant.SUCCESS, "Created", this.message);
                    } else {

                      this.message = this.message + "\n" + "Error in adding Pharmacy Admin in PrimeRx POS";
                      this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                    }
                  });
                }
              }
            });
          } else {
            forkJoin([mposCreateStoreAdmin]).subscribe(responses => {
              console.log('mpos repsonse');


              if (responses[0].result == constant.SUCCESS) {
                this.router.navigateByUrl('/storeAdmin');


                this.message = this.message + "\n" + "Pharmacy Admin added in PrimeRx POS";
                this.utilityService.showAlert(constant.SUCCESS, "Created", this.message);
              } else {

                this.message = this.message + "\n" + "Error in adding Pharmacy Admin in PrimeRx POS";
                this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
              }
            });
          }

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          if (response.data != undefined && response.data != null) {
            const errorMsg = JSON.parse(response.data);

            this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, errorMsg.Message);
          } else {

            this.utilityService.showAlert(constant.FAILURE, "Failed", "Error in creating Pharmacy Admin");
          }
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });

  }

  UpdateStoreAdmin(user: any) {

    user.userName = this.storeData.userName + '@' + this.pseudoName;

    this.storeAdminService.Update(user).subscribe(response => {

      if (response.result == constant.SUCCESS) {
        this.message = "Pharmacy Admin updated in Store configurator";

        let store = this.storeService.GetStoreByNPI(user.NPINo);

        forkJoin([store]).subscribe(responses => {

          if (responses[0].result == constant.SUCCESS) {

            let storeData = JSON.parse(responses[0].data);

            let ediUpdateStoreAdmin = this.EDIstoreAdminService.Update(user);
            let mposUpdateStoreAdmin = this.MPOSstoreAdminService.Update(user);

            if (storeData.isEdiv2) {
              forkJoin([ediUpdateStoreAdmin]).subscribe(responses => {
                console.log('edi repsonse');

                if (responses[0].result == constant.SUCCESS) {
                  //this.message = this.message + "\n" + "Pharmacy Admin updated in EDI CLOUD";
                  if (storeData.isMpos) {
                    forkJoin([mposUpdateStoreAdmin]).subscribe(responses => {
                      console.log('mpos repsonse');

                      if (responses[0].result == constant.SUCCESS) {

                        this.message = this.message + "\n" + "Pharmacy Admin updated in PrimeRx POS";
                        this.utilityService.showAlert(constant.SUCCESS, "Updated", this.message).subscribe(result => {

                          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                          this.dialogService.close(true);
                        });
                      } else {

                        this.message = this.message + "\n" + "Error in updating Pharmacy Admin in PrimeRx POS";
                        this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                      }
                    });
                  }

                  this.utilityService.showAlert(constant.SUCCESS, "Updated", this.message).subscribe(result => {

                    //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                    this.dialogService.close(true);
                  });
                } else {
                  //this.message = this.message + "\n" + "Error in updating Pharmacy Admin in EDI CLOUD";
                  if (storeData.isMpos) {
                    forkJoin([mposUpdateStoreAdmin]).subscribe(responses => {
                      console.log('mpos repsonse');

                      if (responses[0].result == constant.SUCCESS) {

                        this.message = this.message + "\n" + "Pharmacy Admin updated in PrimeRx POS";
                        this.utilityService.showAlert(constant.SUCCESS, "Updated", this.message).subscribe(result => {

                          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                          this.dialogService.close(true);
                        });
                      } else {

                        this.message = this.message + "\n" + "Error in updating Pharmacy Admin in PrimeRx POS";
                        this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                      }
                    });
                  }

                  this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, this.message);
                }

              });
            } else {
              forkJoin([mposUpdateStoreAdmin]).subscribe(responses => {
                console.log('mpos repsonse');


                if (responses[0].result == constant.SUCCESS) {

                  this.message = this.message + "\n" + "Pharmacy Admin updated in PrimeRx POS";
                  this.utilityService.showAlert(constant.SUCCESS, "Updated", this.message).subscribe(result => {

                    //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                    this.dialogService.close(true);
                  });
                } else {

                  this.message = this.message + "\n" + "Error in updating Pharmacy Admin in PrimeRx POS";
                  this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                }
              });
            }
          }


        });


      } else {
        this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);

      }

    }, error => {
      this.utilityService.showAlert(constant.FAILURE, "Failed", "Error in updating Pharmacy Admin");
    })

  }

  DeleteStoreAdmin(user: any) {
    let passData = {
      promptTitle: "Are you sure ?",
      promptMessage: "You won't be able to revert this!",
    };
    this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
      if (result == true) {
        user.isDelete = true;
        user.userName = this.storeData.userName + '@' + this.pseudoName;

        this.storeAdminService.Delete(user).subscribe(response => {

          if (response.result == constant.SUCCESS) {
            this.message = "Pharmacy Admin deleted in Store Configurator";

            let store = this.storeService.GetStoreByNPI(user.NPINo);

            forkJoin([store]).subscribe(responses => {

              if (responses[0].result == constant.SUCCESS) {

                let storeData = JSON.parse(responses[0].data);

                const isEdiv2 = storeData?.isEdiv2 ?? false;
                const isMpos = storeData?.isMpos ?? false;

                let ediDeleteStoreAdmin = this.EDIstoreAdminService.Delete(user);
                let mposDeleteStoreAdmin = this.MPOSstoreAdminService.Delete(user);

                if (isEdiv2) {
                  forkJoin([ediDeleteStoreAdmin]).subscribe(responses => {
                    console.log('edi repsonse');

                    if (responses[0].result == constant.SUCCESS) {
                      //this.message = this.message + "\n" + "Pharmacy Admin deleted in EDI CLOUD";
                      if (storeData.isMpos) {
                        forkJoin([mposDeleteStoreAdmin]).subscribe(responses => {
                          console.log('mpos repsonse');

                          if (responses[0].result == constant.SUCCESS) {

                            this.message = this.message + "\n" + "Pharmacy Admin deleted in PrimeRx POS";
                            this.utilityService.showAlert(constant.SUCCESS, "Deleted", this.message);
                          } else {

                            this.message = this.message + "\n" + "Error in deleting Pharmacy Admin in PrimeRx POS";
                            this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                          }
                        });
                      }
                    } else {
                      //this.message = this.message + "\n" + "Error in deleting Pharmacy Admin in EDI CLOUD";
                      if (isMpos) {
                        forkJoin([mposDeleteStoreAdmin]).subscribe(responses => {
                          console.log('mpos repsonse');

                          if (responses[0].result == constant.SUCCESS) {

                            this.message = this.message + "\n" + "Pharmacy Admin deleted in PrimeRx POS";
                            this.utilityService.showAlert(constant.SUCCESS, "Deleted", this.message);
                          } else {

                            this.message = this.message + "\n" + "Error in deteting Pharmacy Admin in PrimeRx POS";
                            this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                          }
                        });
                      }
                    }
                  });
                } else {
                  forkJoin([mposDeleteStoreAdmin]).subscribe(responses => {
                    console.log('mpos repsonse');


                    if (responses[0].result == constant.SUCCESS) {

                      this.message = this.message + "\n" + "Pharmacy Admin deleted in PrimeRx POS";
                      this.utilityService.showAlert(constant.SUCCESS, "Deleted", this.message);
                    } else {

                      this.message = this.message + "\n" + "Error in deleting Pharmacy Admin in PrimeRx POS";
                      this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
                    }
                  });
                }
              }

            });

          } else {
            this.utilityService.showAlert("ERROR", "Failed", "Failed to delete Pharmacy Admin");
          }


        }, error => {
          this.utilityService.showAlert("ERROR", "Failed", "Failed to delete Pharmacy Admin");

          console.log(error);
        })

      } else if (result.isDenied) {
      }
    })



  }

  keyPressAlphaNumericWithCharacters(event: any) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9-_!@#$%&*()-+=,.?/'";: ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  getPrimeRxPOSAdminByUserName(userName: string) {
    if (userName) {
      let userPayload = {
        userName: userName
      }
      this.MPOSstoreAdminService.getPrimeRxPOSAdminByUserName(userPayload).subscribe({
        next: (response) => {
          if (response.result == ResponseStatus.SUCCESS) {

            const apiResponse = JSON.parse(response.data);

            this.primeRxPOSAdmin = apiResponse;
            //showing latest password from primerx pos admin
            this.Store.password = this.primeRxPOSAdmin.password;
            if (this.primeRxPOSAdmin.isLocked) {
              this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "User is locked Please update the user to unlock it");
            }
          } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {
            // this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
          }
        },
        error: (errorResponse) => {
          console.log(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
        }
      });
    }
  }

}
