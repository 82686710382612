import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'console';
import { catchError, filter, map, mergeMap, retry, retryWhen, take, throwError, timer } from 'rxjs';
import { ediconstant } from 'src/app/constant/ediconstant';
import { ErrorHandlerService } from '../error-handler.service';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';

@Injectable({
  providedIn: 'root'
})
export class VendorPriceUpdateService {

  constructor(private baseHttpService: BaseHttpService) { }

  UpdateCronScheduler(cronExpression: any) {
    return this.baseHttpService.post<any>(ediconstant.updatePriceUpdateCronScheduler, cronExpression, ediconstant.httpClientHeader.headers);
  }
}
