import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { PharmacyConfiguration } from 'src/app/models/ProviderConfiguraion/PharmacyConfiguration';
import { GetProviderRequest } from 'src/app/models/RequestModel/GetProviderRequest';
import { ProviderService } from 'src/app/services/provider.service';
import { ServiceProviderModalComponent } from './service-provider-modal/service-provider-modal.component';
import { Observable, forkJoin, switchMap } from 'rxjs';
import { UtilityService } from 'src/app/services/utility.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { EdiUserAccess } from 'src/app/models/EdiUserAccess';
import { PageHeaderComponent } from 'src/app/ReusableComponents/page_header/page-header/page-header.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-message-service-configuration',
  templateUrl: './message-service-configuration.component.html',
  styleUrls: ['./message-service-configuration.component.css']
})
export class MessageServiceConfigurationComponent implements OnInit {
  searchProvider: GetProviderRequest = new GetProviderRequest;
  currentPage = 0;
  pageSize = 25;
  totalRows = 0;
  token: string;
  tokenData: any;
  expiryTime: number = 0;//Date.now() + (86400 * 1000);

  providersArray: any=[];
  activeStatus: any[] = [];
  activeValue: number=0;

  userAccessData!: EdiUserAccess;

  displayedColumns = [
    'select',
    'SerialNo',
    'identifier', //NPI
    'nabp',
    'pharmacyName',
    'address',
    'providerID',
    'tollFreePhoneNumber',
    'active',
    'Action',
  ];

  
  pageSizeOptions: number[] = [10, 25, 50, 100];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<PharmacyConfiguration>;
  
  providersNPIArray: any = [];
  allSelected = false;
  anySelected: boolean = false;
  migratedPharamacies: number=0;

  constructor(private dialogService: MatDialog, 
    private providerSearch: ProviderService, 
    private providerService: ProviderService,
    private utilityService: UtilityService,
    private tokenStorageService:TokenStorageService,
    private snackBar: MatSnackBar) 
    { 
      const user=this.tokenStorageService.getUser();
      if (!this.userAccessData) {
        this.userAccessData = {} as any;
    }
    
    this.userAccessData.NPINo = user.NPINo;
    this.userAccessData.pseudoName = user.pseudoName;
    }

  ngOnInit(): void {
    this.token = sessionStorage.getItem(constant.AUTH0_TOKEN_KEY) || '';
    this.getAllProviders();
    // this.providersArray.push({providerID:1,Name:"All",IsActive:true})
    // this.providersArray.push({providerID:2,Name:"Twilio",IsActive:true})
    // this.providersArray.push({providerID:3,Name:"Bandwidth",IsActive:true})

    this.activeStatus.push({statusID:0,statusName:"All"})
    this.activeStatus.push({statusID:1,statusName:"Active"})
    this.activeStatus.push({statusID:2,statusName:"Inactive"})
   
    this.initializeDataSource();


      this.searchProvider={
        identifier:"",
        providerID:0,
        pharmacyName:"",
        isActive:true,
        tollFreePhoneNumber:"",
        nabp:""
      }

      switch (this.searchProvider.isActive) {
        case true:
          this.activeValue=1
          break;
        case false:
          this.activeValue=2
          break;
        default:
          this.activeValue=0
          break;
      }
      
    this.activeValue=1;


    this.SearchProviderData();
    this.dataSource.paginator = this.paginator!;
    this.dataSource.sort = this.sort!;
  }

  
  get totalRecords() {
    return this.providersNPIArray.length;
  }

  initializeDataSource() {
    this.dataSource = new MatTableDataSource<PharmacyConfiguration>(this.providersNPIArray);
    this.dataSource.paginator = this.paginator!;
    this.dataSource.sort = this.sort;

    setTimeout(() => {
      this.paginator!.pageIndex = this.currentPage;
      this.paginator!.length = this.providersNPIArray.length;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator!;
    this.dataSource.sort = this.sort!;
  }

  //#region Migration
  toggleSelectAll(event: any) {
    const isChecked = event.checked;
    this.allSelected = isChecked;
    this.dataSource.data.forEach(element => {
      if (element.providerID === 1) {
        element.selected = isChecked;
      }
    });
    this.updateAnySelected();
  }

  someSelected() {
    const numSelected = this.dataSource.data.filter(element => element.selected && element.providerID === 1).length;
    const numEligible = this.dataSource.data.filter(element => element.providerID === 1).length;
    return numSelected > 0 && numSelected < numEligible;
  }

  updateAnySelected() {
    this.anySelected = this.dataSource.data.some(record => record.selected);
  }

  getSelectedConfiguration(): PharmacyConfiguration[] {
    return this.dataSource.data.filter(record => record.selected);
  }


  

  MigrateProvider() {
    const selectedConfigurations: PharmacyConfiguration[] = this.getSelectedConfiguration();
    console.log('Selected Records:', selectedConfigurations);
    
    const bandwidthProvider = this.providersArray.find(provider => provider.name === 'Bandwidth');
    
    if (bandwidthProvider) {
      const bandwidthId = bandwidthProvider.providerID;
  
      let passData = {
        promptTitle: "Continue?",
        promptMessage: "This will change provider for the selected pharmacies!",
      };
  
      this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
        if (result === true) {
          this.migratedPharamacies = 0; // Reset migrated count
  
          const observables = selectedConfigurations.map(config => {
            return new Observable(observer => {
              config.providerID=bandwidthId;
              this.UpdateProvider(config).subscribe({
                next: () => {
                  this.migratedPharamacies=this.migratedPharamacies+1
                  observer.next();
                  observer.complete();
                  
                },
                error: (error) => {
                  observer.error(error);
                }
              });
            });
          });
  
          forkJoin(observables).subscribe({
            next: () => {
              // All updates completed successfully
              if(this.migratedPharamacies == selectedConfigurations.length)
              {
                this.snackBar.open('Providers migrated to Bandwidth successfully!', 'Close', {
                  duration: 3000,
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom'
                });
    
                this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, "Providers migrated to Bandwidth successfully!");
              }
              else
              {
                this.snackBar.open('Could not migrate for all the selected pharmacies', 'Close', {
                  duration: 3000,
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom'
                });
    
                this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Could not migrate for all the selscted pharmacies");
              }
             
            },
            error: (error) => {
              // Error occurred during migration
              console.error(error);
              this.snackBar.open('Some providers could not be migrated to Bandwidth', 'Close', {
                duration: 3000,
                horizontalPosition: 'center',
                verticalPosition: 'bottom'
              });
  
              this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Some providers could not be migrated to Bandwidth");
            }
          });
        }
      });
    } else {
      this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Invalid Provider, Bandwidth provider not found");
    }
  }
  
  UpdateProvider(pharmacyConfiguration: any): Observable<any> {
      return this.providerService.UpdateProviderConfig(this.token, pharmacyConfiguration);
   
  }
  


  getAllProviders(){
    const includeInActive:boolean=false;
    
      this.providerSearch.GetAllProviders(this.token, includeInActive).subscribe({
        next: (response) => {
          if (response) {
            this.providersArray = response;
            if (this.providersArray.length>0)
            {
              this.providersArray.unshift({providerID:0,name:"All",isActive:true})
              this.searchProvider.providerID=0;
            }
            
            console.log("Received all providers")
          }
          else
          {
            console.log("Unable to received all providers")
            this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Could not load providers list");
          }
        },
        error: (errorResponse) => {
          console.error(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Error while loading providers list");
        }
      });
    //}
  }

  
  OpenProviderDetailsModal(){
    const ProviderConfigurationDialogRef = this.dialogService.open(ServiceProviderModalComponent, {
      width: '50%',
      disableClose: true,
      data: {
        headerTitle: 'Add New Pharmacy Provider Configuration',
        currentConfiguration: null,
        allConfiguration: this.providersNPIArray
      },
    });

    ProviderConfigurationDialogRef.afterClosed().subscribe(result => {
      this.SearchProviderData();
    });
  }
  OpenProviderUpdateModal(providerData:any){
    const ProviderConfigurationDialogRef = this.dialogService.open(ServiceProviderModalComponent, {
      width: '50%',
      disableClose: true,
      data: {
        headerTitle: 'Update Pharmacy Provider Configuration',
        currentConfiguration: providerData,
        allConfiguration: this.providersNPIArray,
        tokenData: this.tokenData
      },
    });

    ProviderConfigurationDialogRef.afterClosed().subscribe(result => {
      this.SearchProviderData();
    });

  }

  SearchProviderData(pageChanged: boolean = false) {
   
    this.providersNPIArray = [];
    const searchRequest:any={};
    if(this.searchProvider.identifier.trim().length>0) searchRequest.identifier=this.searchProvider.identifier;
    if(this.searchProvider.providerID!=0) searchRequest.providerID=this.searchProvider.providerID;
    if(this.searchProvider.pharmacyName.trim().length>0) searchRequest.pharmacyName=this.searchProvider.pharmacyName;
    if(this.searchProvider.nabp.trim().length>0) searchRequest.nabp=this.searchProvider.nabp;
    if(this.activeValue!=0)
    {
      switch (this.activeValue) {
        case 1:
          searchRequest.isActive=true
        break;
        case 2:
          searchRequest.isActive=false
          break;
      }
    }

    if(this.searchProvider.tollFreePhoneNumber.trim().length>0) searchRequest.tollFreePhoneNumber=this.searchProvider.tollFreePhoneNumber;
    
      this.providerSearch.SearchProviderConfiguration(this.token, searchRequest).subscribe({
        next: (response) => {
          const result = response.result ? JSON.parse(response.result) : response;
          if (result.StatusCode==0) {
            this.utilityService.showAlert(constant.KEY_WARNING, constant.KEY_WARNING, result.StatusDescription);
          } 
          else 
          {
            this.providersNPIArray = response;
  
            if (this.providersNPIArray.length > 0) {
              this.providersNPIArray.sort((a, b) => {
                if (a.identifier < b.identifier) {
                  return -1;
                }
                if (a.identifier > b.identifier) {
                  return 1;
                }
                return 0;
              });
              this.initializeDataSource();
            } else {
              this.dataSource = new MatTableDataSource<PharmacyConfiguration>(this.providersNPIArray);
              //this.utilityService.showAlert('ERROR', 'Failed', 'No Data Found');
              this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, 'No Data Found');
            }
          }
        },
        error: (errorResponse) => {
          console.log(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
        }
      });
    

    
  }

  DeleteProviderDetails(pharmacyConfiguration: PharmacyConfiguration){
    let passData = {
      promptTitle: "Are you sure ?",
      promptMessage: "You won't be able to revert this!",
    };

    this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
      if (result == true) {
            this.providerService.DeleteProviderConfig(this.token, pharmacyConfiguration).subscribe({
            next: (response) => {
              if (response.result == ResponseStatus.SUCCESS) {
                this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_UPDATE_TITLE, "Configuration deleted successfully").subscribe(result => {
                  //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                  //this.dialogService.close(true);
                });
              } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {
                this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
              }
            },
            error: (errorResponse) => {
              console.log(errorResponse);
              this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
            }
          });
        
      }
    });
   
  }

  


  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    
  }

  toggleCommentExpansion(element: any): void {
    element.isExpanded = !element.isExpanded;
  }

  keyPressNumeric(event: any) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }


}