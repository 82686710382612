<div fxLayout="row wrap" style="padding: 15px;">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex.gt-lg="15" fxFlexAlign="center" fxFlex.gt-md="15" fxFlex.gt-xs="100" fxFlex="100">
            <app-page-header
            [icon]="'assets/images/icons/icon-EDI-blue.jpeg'"
            [title]="'Vendor Mapping'"
            [breadcrumb]="''"
            ></app-page-header>
        </div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 10px;">
            <mat-form-field>
                <mat-label>Select Vendor</mat-label>
                <mat-select #vendorCode="ngModel" name="vendorCode" [(ngModel)]="vendor.vendorCode">
                    <mat-option *ngFor="let eachVendor of vendorArray" [value]="eachVendor.vendor.vendorCode">
                        {{ eachVendor.vendor.vendorCode}}
                    </mat-option>
                </mat-select>

            </mat-form-field>
        </div>
        <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100" style="padding-left: 10px;">
        </div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100" style="padding-top:10px;">
            <mat-form-field>
                <mat-label>Select NPINo</mat-label>
                <input type="text" matInput [formControl]="npiControl" [matAutocomplete]="auto" (input)="onInput($event)"
                maxlength="10" (keypress)="keyPressNumeric($event)">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayNPINos"
                    (optionSelected)="setNPINoToModel($event);">
                    <ng-container *ngIf="filteredOptions | async as options">
                      <mat-option *ngFor="let option of options" [value]="option">
                        {{option.NPINo}} -- {{option.storeName}}
                      </mat-option>
                      <!-- Show "No data found" when there are no options --> <!--EPPOS-3895-->
                      <mat-option *ngIf="options.length === 0" disabled class = "no-data-option">
                        No data found
                      </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100" style="padding-left: 10px;">
                <button  class="custom-blue-button"   type="button" id="btnCreate" style="width: 85px;"
                (click)="getVendors()">
                <mat-icon color="white">search</mat-icon>Search
            </button>
            </div>
        </div>
        <div fxFlex="auto" fxLayoutAlign="end center">
            <button  class="custom-orange-button"   type="button" id="btnCreate" style="width: 125px;"
                (click)="openMappingDialog()">
                <mat-icon color="white">add</mat-icon>Map With NPI

                </button>
        </div>
    </div>
</div>


<!-- <div fxLayout="row wrap" fxLayout.xs="column">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card>
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100"
                    style="padding-top: 10px;padding-right: 10px;">
                    <button mat-raised-button matTooltip="Export to excel file"
                        (click)="exporter.exportTable('xlsx', {fileName:'vendor_Data', sheet: 'vendor_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Excel
                    </button>
                    <button mat-raised-button matTooltip="Export to csv file"
                        (click)="exporter.exportTable('csv',{fileName:'vendor_Data', sheet: 'vendor_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Csv
                    </button>
                    <button mat-raised-button matTooltip="Export to json file"
                        (click)="exporter.exportTable('json',{fileName:'vendor_Data', sheet: 'vendor_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Json
                    </button>
                    <button mat-raised-button matTooltip="Export to txt file"
                        (click)="exporter.exportTable('txt',{fileName:'vendor_Data', sheet: 'vendor_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Txt
                    </button>
                </div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100"></div>

            </div>

        </mat-card>
    </div>
</div> -->
<div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class=" mat-elevation-z24" style="background-color: transparent;">
                <div class="mat-elevation-z8 m-12" style="box-shadow: none;">
                    <div class="table-container" style="width: 100%;">
                        <table mat-table matTableExporter #outerSort="matSort" matSort
                                    [dataSource]="VendorContractDataSource" #exporter="matTableExporter"
                                    style="border: 1px solid rgb(27, 170, 227);" multiTemplateDataRows
                                    class="mat-elevation-z8">

                                    <ng-container matColumnDef="Expand/Collapse">
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element">
                                            <mat-icon class="pointer" matSuffix matTooltip="See Vendor Account(s)"
                                                (click)="showNested = !showNested">{{showNested ?
                                                'expand_less' : 'expand_more'}}
                                            </mat-icon>
                                        </td>
                                    </ng-container>

                                    <ng-container color="primary" matColumnDef="NPINo">
                                        <th mat-header-cell color="primary" *matHeaderCellDef mat-sort-header>NPINo
                                        </th>
                                        <td mat-cell color="primary" *matCellDef="let element"> {{element.vendor.NPINo}}
                                        </td>
                                    </ng-container>

                                    <!--EPPOS-3766-->
                                    <ng-container color="primary" matColumnDef="PharmacyName">
                                      <th mat-header-cell color="primary" *matHeaderCellDef mat-sort-header>Pharmacy Name
                                      </th>
                                      <td mat-cell color="primary" *matCellDef="let element"> {{element.vendor.PharmacyName}}
                                      </td>
                                    </ng-container>

                                    <ng-container color="primary" matColumnDef="vendorCode">
                                        <th mat-header-cell color="primary" *matHeaderCellDef mat-sort-header>Vendor
                                            Code </th>
                                        <td mat-cell color="primary" *matCellDef="let element">
                                            {{element.vendor.vendorCode}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="vendorName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Vendor Name </th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor.vendorName}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="address1">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Address1</th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor.address1}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="city">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> City</th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor.city}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="state">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> State</th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor.state}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="zip">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Zip</th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor.zip}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="isActive">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Is Active?</th>
                                        <td mat-cell *matCellDef="let element"> {{element.vendor.isActive}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Action">
                                        <th mat-header-cell *matHeaderCellDef> Action </th>
                                        <td mat-cell *matCellDef="let element">
                                            <!-- <button mat-icon-button matTooltip="Edit {{element.vendor.vendorCode}}"
                                                (click)="openVendorMappingUpdateDialog(element.vendor);"
                                                color="primary">
                                                <mat-icon>mode_edit</mat-icon>
                                            </button> -->
                                            <app-table-edit-button
                                            [imageSrc]="''"
                                            [tooltip]="'Edit ' + element.vendor.vendorCode"
                                            [color]="'primary'"
                                            (buttonClick)="openVendorMappingUpdateDialog(element.vendor)">
                                            </app-table-edit-button>
                                            <!-- <button mat-flat-button color="primary"
                                                (click)="AddContract(element.vendor.NPINo,element.vendor.vendorCode);">
                                                <mat-icon>add_circle</mat-icon>
                                            </button> -->
                                            <!-- <button mat-icon-button matTooltip="Add Account"  (click)="AddContract(element.vendor.NPINo,element.vendor.vendorCode)">
                                                <mat-icon>add</mat-icon>Add
                                            </button> -->
                                            <button mat-icon-button matTooltip="Add Contract"
                                            (click)="AddContract(element.vendor.NPINo,element.vendor.vendorCode)" color="#0c7bb8" style="color: #0c7bb8;font-size: small;">
                                            <mat-icon color="#0c7bb8">add</mat-icon>Add Contract
                                            </button>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="expandedDetail">
                                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                            <div class="example-element-detail"
                                                *ngIf="element.contractDetail?.data.length"
                                                [@detailExpand]="element ==  expandedElement ? 'expanded' : 'collapsed'">
                                                <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">

                                                    <table #innerTables mat-table #innerSort="matSort"
                                                        style="border: 1px solid rgb(27, 170, 227);"
                                                        [dataSource]="element.contractDetail" matSort>

                                                        <ng-container color="primary" matColumnDef="contractName">
                                                            <th mat-header-cell color="primary" *matHeaderCellDef
                                                                mat-sort-header>
                                                                Account Name</th>
                                                            <td mat-cell *matCellDef="let element"  style="padding-left: 5px;padding-right: 10px;">
                                                                {{element.contractName}}
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractUsername">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                ftp/sftp Username</th>
                                                            <td mat-cell color="primary" *matCellDef="let element"  style="padding-left: 5px;padding-right: 10px;">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractUsername"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractUsername}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractPassword">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                ftp/sftp Password </th>
                                                            <td mat-cell color="primary" *matCellDef="let element"  style="padding-left: 5px;padding-right: 10px;">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractPassword"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName" [type]="hideOldPwd ? 'password' : 'text'" autocomplete="new-password">
                                                                            <mat-icon class="pointer" matSuffix (click)="hideOldPwd = !hideOldPwd">{{hideOldPwd ?
                                                                                'visibility_off' :
                                                                                'visibility'}}
                                                                            </mat-icon>
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit" style="font-weight: bolder;">
                                                                                ******
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractIsaInterchangeSenderId">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                ISA Interchange Sender ID</th>
                                                            <td mat-cell color="primary" *matCellDef="let element"  style="padding-left: 5px;padding-right: 10px;">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractIsaInterchangeSenderId"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractIsaInterchangeSenderId}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractAppSenderCode">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                App Sender Code</th>
                                                            <td mat-cell color="primary" *matCellDef="let element"  style="padding-left: 5px;padding-right: 10px;">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractAppSenderCode"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractAppSenderCode}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractIdentificationCodeBy">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Identification Code By</th>
                                                            <td mat-cell color="primary" *matCellDef="let element"  style="padding-left: 5px;padding-right: 10px;">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractIdentificationCodeBy"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractIdentificationCodeBy}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractOutboundDirName">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Outbound Dir Name</th>
                                                            <td mat-cell color="primary" *matCellDef="let element"  style="padding-left: 5px;padding-right: 10px;">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractOutboundDirName"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractOutboundDirName}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractInboundDirName">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Inbound Dir Name</th>
                                                            <td mat-cell color="primary" *matCellDef="let element" style="padding-left: 5px;padding-right: 10px;">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractInboundDirName"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractInboundDirName}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractPriceFileFormat">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Price FileFormat</th>
                                                            <td mat-cell color="primary" *matCellDef="let element"  style="padding-left: 5px;padding-right: 10px;">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractPriceFileFormat"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractPriceFileFormat}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractAckFileFormat">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Ack FileFormat</th>
                                                            <td mat-cell color="primary" *matCellDef="let element"  style="padding-left: 5px;padding-right: 10px;">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractAckFileFormat"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractAckFileFormat}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="contractFileFormat810">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Invoice FileFormat</th>
                                                            <td mat-cell color="primary" *matCellDef="let element"  style="padding-left: 5px;padding-right: 10px;">
                                                                <div *ngIf="element.isEdit">
                                                                    <mat-form-field
                                                                        style="padding-left: 5px;padding-right: 5px;">
                                                                        <input matInput
                                                                            [(ngModel)]="element.contractFileFormat810"
                                                                            name="enteredName" #enteredName="ngModel"
                                                                            id="enteredName">
                                                                    </mat-form-field>
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{element.contractFileFormat810}}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="useQntPack">
                                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                Use Pack Quantity?
                                                            </th>
                                                            <td mat-cell color="primary" *matCellDef="let element" style="padding-left: 5px; padding-right: 10px;">
                                                                <div *ngIf="element.isEdit">
                                                                    <!-- <mat-form-field style="padding-left: 5px; padding-right: 5px;"> -->
                                                                        <mat-checkbox [(ngModel)]="element.useQntPack" name="useQntPackCheckbox" id="useQntPackCheckbox">
                                                                        </mat-checkbox>
                                                                    <!-- </mat-form-field> -->
                                                                </div>
                                                                <div *ngIf="!element.isEdit">
                                                                    {{ element.useQntPack ? 'Yes' : 'No' }}
                                                                </div>
                                                            </td>
                                                        </ng-container>

                                                        <ng-container matColumnDef="Edit">
                                                            <th mat-header-cell *matHeaderCellDef> Edit </th>
                                                            <td mat-cell *matCellDef="let element"  style="padding-left: 5px;padding-right: 10px;">
                                                                <div *ngIf="!element.isEdit">
                                                                    <!-- <button mat-icon-button
                                                                        matTooltip="Edit {{element.contractName}}"
                                                                        (click)="onEdit(element)" color="primary">
                                                                        <mat-icon>mode_edit</mat-icon>
                                                                    </button> -->
                                                                    <app-table-edit-button
                                                                    [imageSrc]="''"
                                                                    [tooltip]="'Edit ' + element.contractName"
                                                                    [color]="'primary'"
                                                                    (buttonClick)="onEdit(element)">
                                                                    </app-table-edit-button>
                                                                    <!-- <button mat-icon-button
                                                                        matTooltip="Delete {{element.contractName}}"
                                                                        (click)="onDelete(element);" color="primary">
                                                                        <mat-icon>delete</mat-icon>
                                                                    </button> -->
                                                                    <app-table-delete-button
                                                                    [imageSrc]="''"
                                                                    [tooltip]="'Delete ' + element.contractName"
                                                                    [color]="'primary'"
                                                                    (buttonClick)="onDelete(element)">
                                                                    </app-table-delete-button>
                                                                </div>
                                                                <div *ngIf="element.isEdit">
                                                                    <button mat-icon-button
                                                                        matTooltip="Update {{element.contractName}} changes"
                                                                        (click)="onUpdate(element);" color="primary">
                                                                        <mat-icon>check_circle_outline </mat-icon>
                                                                    </button>
                                                                    <button mat-icon-button
                                                                        matTooltip="Cancel {{element.contractName}} changes"
                                                                        (click)="element.isEdit=false" color="primary">
                                                                        <mat-icon>highlight_off</mat-icon>
                                                                    </button>
                                                                    <button
                                                                        mat-flat-button
                                                                        matTooltip="Test {{element.contractName}} connection"
                                                                        (click)="testVendorFtpSftpConnection(element)" color="primary">
                                                                        Test Conn
                                                                    </button>
                                                                </div>

                                                            </td>
                                                        </ng-container>

                                                        <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns">
                                                        </tr>
                                                        <tr mat-row
                                                            *matRowDef="let row; columns: innerDisplayedColumns;">
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                        [ngClass]="{hovered: row.hovered}"
                                        [class.example-element-row]="row.contractDetails?.data.length"
                                        [class.example-expanded-row]="expandedElement === row"
                                        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
                                        (click)="toggleRow(row)"></tr>

                                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
                                        class="example-detail-row">
                                    </tr>
                        </table>
                    </div>

                    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
                    </mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>
