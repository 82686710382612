import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { Observable, catchError, filter, map, mergeMap, retry, retryWhen, take, throwError, timer } from "rxjs";
import { constant } from "../constant/constant";
import { ErrorHandlerService } from "./error-handler.service";
import { ApiResponse } from "../models/ApiResponse";
import { BaseHttpService } from "../utility/BaseHttpService"

@Injectable({
  providedIn: 'root'
})

export class ProviderService {
  constructor(private baseHttpService: BaseHttpService) { }

  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

  // GetProviderConfiguration(token: string, providerrequest: any) {
  //   console.log(constant.PROVIDER_CONFIGURATION_SERVICE_URL + '/' + constant.GET_PROVIDER_CONFIGURATION)
  //   const headers = constant.getHttpClientHeaderWithToken(token);
  //   return this.httpClient.post<ApiResponse<any>>((constant.PROVIDER_CONFIGURATION_SERVICE_URL + constant.GET_PROVIDER_CONFIGURATION), providerrequest, headers).pipe(
  //     catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
  //   );
  // }

  SearchProviderConfiguration(token: string, serachrequest: any) {
    const headers = constant.getHttpClientHeaderWithToken(token);
    const searchUrl=constant.PROVIDER_CONFIGURATION_SERVICE_URL + constant.SEARCH_PROVIDER_CONFIGURATION
    //const searchUrl="https://primecomm.dev.eprimerx.com/api/v1/ProviderConfiguration/Search";
    //const searchUrl=constant.PROVIDER_CONFIGURATION_SERVICE_URL + constant.SEARCH_PROVIDER_CONFIGURATION;
    //const searchUrl="api/v1/ProviderConfiguration/Search";
    return this.baseHttpService.post<ApiResponse<any>>(searchUrl, serachrequest, headers.headers);
  }


  GetPharmacyNameByNpi(token: string, identifierType: string, IdentifierID:string) {
    const headers = constant.getHttpClientHeaderWithToken(token);
    const url = `${constant.PROVIDER_CONFIGURATION_SERVICE_URL}${constant.GET_PHARMACY_DETAILS}?identifierType=${identifierType}&IdentifierID=${IdentifierID}`;
    return this.baseHttpService.get<any>(url, headers.headers);
  }

  UpdateProviderConfig(token: string, pharmacyconfig: any) {
    const headers = constant.getHttpClientHeaderWithToken(token);
    const url = constant.PROVIDER_CONFIGURATION_SERVICE_URL + constant.UPDATE_PROVIDER_CONFIGURATION;
    return this.baseHttpService.post<ApiResponse<any>>(url, pharmacyconfig, headers.headers);
  }

  InsertProviderConfig(token: string, pharmacyconfig: any) {
    const headers = constant.getHttpClientHeaderWithToken(token);
    const url = constant.PROVIDER_CONFIGURATION_SERVICE_URL + constant.INSERT_PROVIDER_CONFIGURATION;
    return this.baseHttpService.post<ApiResponse<any>>(url, pharmacyconfig, headers.headers);
  }

  DeleteProviderConfig(token: string, pharmacyconfig: any) {
    const headers = constant.getHttpClientHeaderWithToken(token);
    const url = constant.PROVIDER_CONFIGURATION_SERVICE_URL + constant.DELETE_PROVIDER_CONFIGURATION;
    return this.baseHttpService.post<ApiResponse<any>>(url, pharmacyconfig, headers.headers);
  }

  // getToken(): Observable<any> {

  //   const headers = constant.getHttpClientHeaderForToken();

  //   const body = new HttpParams()
  //     .set('grant_type', 'client_credentials')
  //     .set('audience', constant.OAUTH_AUDIENCE);

  //   //return this.httpClient.post(constant.GET_OAUTH_TOKEN, body, headers);
  //   return this.httpClient.post<ApiResponse<any>>((constant.GET_OAUTH_TOKEN), body, headers).pipe(
  //     catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
  //   );
  // }


  GetAllProviders(token: string, includeInActive:boolean) {
    const headers = constant.getHttpClientHeaderWithToken(token);
    const url = constant.PROVIDER_CONFIGURATION_SERVICE_URL + constant.GET_ALL_PROVIDERS;
    return this.baseHttpService.get<any>(url, headers.headers);
  }

  // GetStoreAdminOnLoad() {

  //   return this.httpClient.get<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.GET_ALL_STORES_ADMIN_ON_LOAD), constant.httpClientHeader).pipe(
  //     catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
  //   );
  // }
}
