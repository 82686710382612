import { Injectable } from '@angular/core';
import { ediconstant } from 'src/app/constant/ediconstant';
import { FileParseErrorRequest } from 'src/app/models/RequestModel/FileParseErrorRequest';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';

@Injectable({
  providedIn: 'root'
})
export class FileParsingErrorService {

  constructor(private baseHttpService: BaseHttpService) { }

  getFileParseErrorData(fileData: FileParseErrorRequest) {
    // return this.httpClient.post<any>(constant.getfileParseErrorUrl, fileData, constant.httpClientHeader);
    return this.baseHttpService.post<any>(ediconstant.getfileParseErrorUrl, fileData, ediconstant.httpClientHeader.headers);
  }
}
