import { Injectable } from "@angular/core";
import { BaseHttpService } from "../utility/BaseHttpService";
import { constant } from "../constant/constant";
import { ediconstant } from "../constant/ediconstant";

@Injectable({
  providedIn: 'root'
})
export class EDIStoreService {

  constructor(private baseHttpService: BaseHttpService) {}

  // Search for a store
  SearchStore(store: any) {
    return this.baseHttpService.post<any>(constant.EDI_STORE_SERVICE_URL + constant.GET_STORE_ON_SEARCH, store, constant.httpClientHeader.headers);
  }

  // Insert a new store
  Insert(store: any) {
    return this.baseHttpService.post<any>(constant.EDI_STORE_SERVICE_URL + constant.INSERT_EDI_STORE, store, constant.httpClientHeader.headers);
  }

  // Update an existing store
  Update(store: any) {
    return this.baseHttpService.post<any>(constant.EDI_STORE_SERVICE_URL + constant.UPDATE_EDI_STORE, store, constant.httpClientHeader.headers);
  }

  // Delete a store
  Delete(store: any) {
    return this.baseHttpService.post<any>(constant.EDI_STORE_SERVICE_URL + constant.DELETE_EDI_STORE, store, constant.httpClientHeader.headers);
  }

  // Delete access for a store
  DeleteAccess(store: any) {
    return this.baseHttpService.post<any>(constant.EDI_STORE_SERVICE_URL + constant.DELETE_EDI_STORE_ACCESS, store, constant.httpClientHeader.headers);
  }

  // Get all NPI numbers
  getAllNPINo() {
    return this.baseHttpService.get<any>(ediconstant.getAllNPINoUrl, ediconstant.httpClientHeader.headers);
  }

  // Rollback a store
  RollBackStore(store: any) {
    return this.baseHttpService.post<any>(constant.EDI_STORE_SERVICE_URL + constant.RollBack_STORE, store, constant.httpClientHeader.headers);
  }
}
