
import { BaseSearchFilter } from "./BaseSearchFilter";
import { Pagination } from "./Pagination";

export class FileDownloadRequest extends BaseSearchFilter  {

    fileType!: string;
    pagination: Pagination = new Pagination;
    fileStatus!: string;
    vendorAccount: string;
}