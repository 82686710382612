//#region EPPOS-3788
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, mergeMap, retryWhen, throwError, timer } from 'rxjs';
import { constant } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})

export class ItemService {

  constructor(private httpClient: HttpClient) { }

  insertDefaultItem(item: any) {
    return this.httpClient.post<any>(constant.insertDefaultItem, item, constant.httpClientHeader).pipe(
      retryWhen(errors => errors.pipe(mergeMap((error, attempt) => attempt < 3 && [500, 502, 503, 504].includes(error.status) ? timer(1000) : throwError(() => error)))),//retry logic
      map((response: any) => {
        return response;
      })
    )
  }
}
//#endregion
