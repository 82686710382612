//#region EPPOS-3755
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, mergeMap, retryWhen, throwError, timer } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';

@Injectable({
  providedIn: 'root'
})

export class StateMasterService {

  constructor(private baseHttpService: BaseHttpService) { }

  searchStateCityByZip(zipCode: string) {
    const requestBody = {
      zipCode: zipCode
    };
    return this.baseHttpService.post<string>(constant.stateCityByZip, requestBody, constant.httpClientHeader.headers).pipe(
      map((response: any) => {
        return response;
      })
    )
  }
}
//#endregion
