import { Injectable } from '@angular/core';
import { BaseHttpService } from '../utility/BaseHttpService'; // Import the base service
import { constant } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class NBSProcessorService {

  constructor(private baseHttpService: BaseHttpService) { }

  checkNPINoExists(store: any) {
    return this.baseHttpService.post<any>((constant.NBS_SERVICE_URL + constant.CHECK_NBS_NPINO_STORE), store, constant.httpClientHeader.headers);
  }

  DeleteOTCBenifitsAccess(store: any) {
    return this.baseHttpService.post<any>((constant.NBS_SERVICE_URL + constant.DELETE_OTCBENIFIT_ACCESS), store, constant.httpClientHeader.headers);
  }

  UpdateOTCBenifitsAccess(store: any) {
    return this.baseHttpService.post<any>((constant.NBS_SERVICE_URL + constant.UPDATE_OTCBENIFIT_ACCESS), store, constant.httpClientHeader.headers);
  }

  AddOTCBenifitsAccess(store: any) {
    return this.baseHttpService.post<any>((constant.NBS_SERVICE_URL + constant.ADD_OTCBENIFIT_ACCESS), store, constant.httpClientHeader.headers);
  }

  GetOtcBenifits(store: any) {
    return this.baseHttpService.post<any>(constant.NBS_SERVICE_URL + constant.GET_OTCBENIFITS_PHARMACY, store, constant.httpClientHeader.headers);
  }

  SearchOtcBenifits(store: any) {
    return this.baseHttpService.post<any>((constant.NBS_SERVICE_URL + constant.SEARCH_OTCBENIFITS_PHARMACY), store, constant.httpClientHeader.headers);
  }
}
