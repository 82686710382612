import { Injectable } from '@angular/core';
import { BaseHttpService } from '../utility/BaseHttpService';
import { constant } from 'src/app/constant/constant';
import { ediconstant } from '../constant/ediconstant';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private baseHttpService: BaseHttpService) { }

  // Fetch roles on load with retry logic
  getRolesOnLoad(roleFilter: any) {
    return this.baseHttpService.post<any>(constant.ROLE_SERVICE_URL + constant.getRolesOnLoadUrl, roleFilter, constant.httpClientHeader.headers // Headers from constant
    );
  }

  // Fetch roles with retry logic and response mapping
  getRoles(roleFilter: any) {
    return this.baseHttpService.post<any>(ediconstant.getRoleUrl, roleFilter, constant.httpClientHeader.headers).pipe(
      // Map response data
      map((response: any) => {
        return response;
      })
    );
  }
}
