import { Injectable } from "@angular/core";
import { BaseHttpService } from "../utility/BaseHttpService";
import { constant } from "../constant/constant";
import { ApiResponse } from "../models/ApiResponse";

@Injectable({
  providedIn: 'root'
})
export class PayTypeMasterService {

  constructor(private baseHttpService: BaseHttpService) { }

  // Get all PayTypeMaster records with retry logic
  GetPayTypeMasterAsync() {
    return this.baseHttpService.get<any>(constant.GETMASTERPAYTYPE, constant.httpClientHeader.headers);
  }

  // Get PayTypeMaster records by NPI with retry logic
  GetPayTypeMasterAsyncByNPI(data: any) {
    return this.baseHttpService.post<any>(constant.GETMASTERPAYTYPEBYNPI, data, constant.httpClientHeader.headers);
  }

  // Insert a new PayTypeMaster record by NPI with retry logic
  InsertPayTypeMasterAsyncByNPI(data: any) {
    return this.baseHttpService.post<any>(constant.INSERTMASTERPAYTYPE, data, constant.httpClientHeader.headers);
  }

  // Insert multiple PayTypeMaster records by NPI with retry logic
  InsertManyPayTypeMasterAsyncByNPI(data: any) {
    // InsertManyPayTypeMasterAsyncByNPI logic with retry
    return this.baseHttpService.post<ApiResponse<any>>(constant.INSERTMANYMASTERPAYTYPE, data, constant.httpClientHeader.headers);
  }

  // Update PayTypeMaster record by NPI with retry logic
  UpdatePayTypeMasterAsyncByNPI(data: any) {
    return this.baseHttpService.put<any>(constant.UPDATEMASTERPAYTYPEBYNPI, data, constant.httpClientHeader.headers);
  }

  // Delete PayTypeMaster record by NPI with retry logic
  DeletePayTypeMasterAsyncByNPI(npi: string) {
    return this.baseHttpService.post<any>(constant.DELETEMASTERPAYTYPEBYNPI, npi, constant.httpClientHeader.headers);
  }

  // InsertManyPayTypeMasterAsyncByNPI(data: any) {
  //   return this.httpClient.post<any>((constant.INSERTMANYMASTERPAYTYPE), data, constant.httpClientHeader).pipe(
  //     catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
  //   );
  // }
}
