import { Injectable } from "@angular/core";
import { BaseHttpService } from "../utility/BaseHttpService";
import { constant } from "../constant/constant";
import { ApiResponse } from "../models/ApiResponse";

@Injectable({
  providedIn: 'root'
})
export class EDIStoreAdminService {

  constructor(private baseHttpService: BaseHttpService) { }

  // Get all store admins on load
  GetStoreAdminOnLoad() {
    return this.baseHttpService.post<ApiResponse<any>>(constant.EDI_USER_SERVICE_URL + constant.GET_ALL_STORES_ADMIN_ON_LOAD, constant.httpClientHeader.headers);
  }

  // Search for a store admin
  SearchStoreAdmin(store: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.EDI_USER_SERVICE_URL + constant.GET_STORE_ADMIN_ON_SEARCH, store, constant.httpClientHeader.headers);
  }

  // Insert a new store admin
  Insert(store: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.EDI_USER_SERVICE_URL + constant.INSERT_EDI_STORE_ADMIN, store, constant.httpClientHeader.headers);
  }

  // Update store admin
  Update(store: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.EDI_USER_SERVICE_URL + constant.UPDATE_EDI_STORE_ADMIN, store, constant.httpClientHeader.headers);
  }

  // Delete store admin
  Delete(store: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.EDI_USER_SERVICE_URL + constant.DELETE_EDI_STORE_ADMIN, store, constant.httpClientHeader.headers);
  }

  // Get EDI V2 user
  getEDIV2User(user: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.EDI_USER_SERVICE_URL + constant.getEDIV2UsersUrl, user, constant.httpClientHeader.headers);
  }
}
