import { Injectable } from "@angular/core";
import { BaseHttpService } from "../utility/BaseHttpService";
import { constant } from "../constant/constant";
import { ApiResponse } from "../models/ApiResponse";

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private baseHttpService: BaseHttpService) { }

  // Get all organizations on load
  GetOrganizationOnLoad() {
    return this.baseHttpService.get<ApiResponse<any>>(constant.ORGANIZATION_SERVICE_URL + constant.GET_ALL_ORGANIZATIONS, constant.httpClientHeader.headers);
  }

  // Get organizations by pagination
  GetOrganizationByPagination(pageData: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.ORGANIZATION_SERVICE_URL + constant.GET_ALL_ORGANIZATIONS, pageData, constant.httpClientHeader.headers);
  }

  // Search organization
  SearchOrganization(organization: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.ORGANIZATION_SERVICE_URL + constant.GET_ORGANIZATION_ON_SEARCH, organization, constant.httpClientHeader.headers);
  }

  // Insert new organization
  Insert(organization: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.ORGANIZATION_SERVICE_URL + constant.INSERT_ORGANIZATION, organization, constant.httpClientHeader.headers);
  }

  // Update organization
  Update(organization: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.ORGANIZATION_SERVICE_URL + constant.UPDATE_ORGANIZATION, organization, constant.httpClientHeader.headers);
  }

  // Delete organization
  Delete(organization: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.ORGANIZATION_SERVICE_URL + constant.DELETE_ORGANIZATION, organization, constant.httpClientHeader.headers);
  }
}
