import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BaseHttpService } from '../utility/BaseHttpService'; // Import BaseHttpService
import { constant } from '../constant/constant';
import { catchError, mergeMap, of, retryWhen, throwError, timer } from 'rxjs'; // Import `of` for handling null or empty observables
import { ErrorHandlerService } from "./error-handler.service";

@Injectable({
  providedIn: 'root'
})

export class StoreService {

  constructor(private baseHttpService: BaseHttpService, private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }

  // GetStoreAndAdmin
  GetStoreAndAdmin(data: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.GET_STORE_AND_ADMIN), data, constant.httpClientHeader.headers);
  }

  // SearchStore
  SearchStore(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.GET_STORE_ON_SEARCH), store, constant.httpClientHeader.headers);
  }

  // GetStoreByNPI
  GetStoreByNPI(store: any) {
    let storeData = { NPINo: store };
    return this.baseHttpService.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.GET_STORE__BY_NPI), storeData, constant.httpClientHeader.headers);
  }

  // Insert
  Insert(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.INSERT_STORE), store, constant.httpClientHeader.headers);
  }

  // Update
  Update(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.UPDATE_STORE), store, constant.httpClientHeader.headers);
  }

  // Delete
  Delete(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.DELETE_STORE), store, constant.httpClientHeader.headers);
  }

  // InsertEPrimeRxStore
  InsertEPrimeRxStore(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.INSERT_EPRIMERX_STORE), store, constant.httpClientHeader.headers);
  }

  // checkNPINoExists
  checkNPINoExists(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.CHECK_NPINO_STORE), store, constant.httpClientHeader.headers);
  }

  // CheckShortNameExists
  CheckShortNameExists(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.CHECK_SHORTNAME_STORE), store, constant.httpClientHeader.headers);
  }

  // GetStoreByOrganization
  GetStoreByOrganization(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.GET_STORE_BY_ORGANIZATION), store, constant.httpClientHeader.headers);
  }

  // RollBackStore (Custom handling with error logging)
  RollBackStore(store: any) {
    return this.httpClient.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.RollBack_STORE), store, constant.httpClientHeader).pipe(
      retryWhen(errors => errors.pipe(mergeMap((error, attempt) => attempt < 3 && [500, 502, 503, 504].includes(error.status) ? timer(1000) : throwError(() => error)))),
      catchError((error: HttpErrorResponse) => {
        this.errorHandlerService.logHttpError(error);
        return of(null); // Return an empty observable to complete the stream
      })
    );
  }
}
