import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, mergeMap, of, retryWhen, throwError, timer } from "rxjs";
import { constant } from "../constant/constant";
import { BaseHttpService } from "../utility/BaseHttpService"; // Import the base service
import { ErrorHandlerService } from "./error-handler.service";

@Injectable({
  providedIn: 'root'
})

export class MPOSStoreService {

  constructor(private baseHttpService: BaseHttpService, private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }

  SearchStore(store: any) {
    return this.baseHttpService.post<any>((constant.MPOS_STORE_SERVICE_URL + constant.GET_STORE_ON_SEARCH), store, constant.httpClientHeader.headers);
  }

  Insert(store: any) {
    return this.baseHttpService.post<any>((constant.MPOS_STORE_SERVICE_URL + constant.INSERT_MPOS_STORE), store, constant.httpClientHeader.headers);
  }

  Update(store: any) {
    return this.baseHttpService.post<any>((constant.MPOS_STORE_SERVICE_URL + constant.UPDATE_MPOS_STORE), store, constant.httpClientHeader.headers);
  }

  Delete(store: any) {
    return this.baseHttpService.post<any>((constant.MPOS_STORE_SERVICE_URL + constant.DELETE_MPOS_STORE), store, constant.httpClientHeader.headers);
  }

  DeleteAccess(store: any) {
    return this.baseHttpService.post<any>((constant.MPOS_STORE_SERVICE_URL + constant.DELETE_MPOS_STORE_ACCESS), store, constant.httpClientHeader.headers);
  }

  RollBackStore(store: any) {
    return this.httpClient.post<any>((constant.MPOS_STORE_SERVICE_URL + constant.RollBack_STORE), store, constant.httpClientHeader).pipe(
      retryWhen(errors => errors.pipe(mergeMap((error, attempt) => attempt < 3 && [500, 502, 503, 504].includes(error.status) ? timer(1000) : throwError(() => error)))),
      catchError((error: HttpErrorResponse) => {
        this.errorHandlerService.logHttpError(error);
        return of(null); // Return an empty observable to complete the stream
      })
    );
  }
}
