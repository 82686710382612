<div fxLayout="row wrap" style="padding: 15px;">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">

        <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <app-page-header [icon]="'assets/images/icons/icon-admin-blue.png'" [title]="'User Management'"
                [breadcrumb]="''"></app-page-header>
        </div>

        <div fxFlex="auto" fxLayout="row" fxLayoutAlign="end center" class="search-container"
            style="text-align: right;">
            <fieldset>
                <input type="text" name="storeNo" #organizationName="ngModel" id="storeNo" [(ngModel)]="user.userName"
                    style="width: 300px;" placeholder="User Name" />
                <button class="search-button" type="button" id="btnOpenCreateOrganizationModal"
                    style="width: 80px; height: 40px;" (click)="SearchUser(user)">
                    <mat-icon>search</mat-icon>
                </button>
            </fieldset>
        </div>

        <div fxLayoutAlign="end center" style="margin-left: 10px; padding: 0;">
            <button class="custom-orange-button" type="button"
                style="width: 100px; height: 45px; margin: 0; padding: 0;" (click)="CreateMMSAdmin()">
                Create New
            </button>
        </div>

    </div>
</div>
<div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class=" mat-elevation-z24" style="background-color: transparent;">
                <div class="mat-elevation-z8 m-12" style="box-shadow: none;">
                    <div class="table-container" style="width: 100%;">
                        <mat-table matTableExporter matSort [dataSource]="dataSource" #exporter="matTableExporter">

                            <ng-container color="primary" matColumnDef="userName">
                                <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header> Username
                                </mat-header-cell>
                                <mat-cell color="primary" *matCellDef="let element">
                                    {{element.userName}}{{element.pseudoName}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="lName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.lName}} {{element.fName}}</mat-cell>
                            </ng-container>



                            <ng-container matColumnDef="noOfAttempt">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> No of attempt </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.noOfAttempt}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="isLocked">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Is Locked? </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.isLocked}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="isActive">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Is Active? </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.isActive}} </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="Action">
                                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <app-table-edit-button [imageSrc]="''" [tooltip]="'Update Store'"
                                        [color]="'primary'" (buttonClick)="OpenMMSAdminUpdateModal(element)">
                                    </app-table-edit-button>

                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"
                                [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>

                        </mat-table>
                    </div>


                    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                        [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
                    </mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>