import { Injectable } from '@angular/core';
import { ediconstant } from 'src/app/constant/ediconstant';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';

@Injectable({
  providedIn: 'root'
})
export class VendorOrderService {

  constructor(private baseHttpService: BaseHttpService) { }

  UpdatePOResponse810CronScheduler(cronExpression: any) {
    return this.baseHttpService.post<any>(ediconstant.updateInvoiceCronScheduler, cronExpression, ediconstant.httpClientHeader.headers);
  }

  UpdatePOResponse855CronScheduler(cronExpression: any) {
    return this.baseHttpService.post<any>(ediconstant.updateACKCronScheduler, cronExpression, ediconstant.httpClientHeader.headers);
  }

  UpdatePORequestCronScheduler(cronExpression: any) {
    return this.baseHttpService.post<any>(ediconstant.updatePOCronScheduler, cronExpression, ediconstant.httpClientHeader.headers);
  }
}
