import { Injectable } from '@angular/core';
import { ediconstant } from 'src/app/constant/ediconstant';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';

@Injectable({
  providedIn: 'root'
})
export class VendorPollerService {

  constructor(private baseHttpService: BaseHttpService) { }

  UpdateCronScheduler(cronExpression: any) {
    return this.baseHttpService.post<any>(ediconstant.updateFilePollerCronScheduler, cronExpression, ediconstant.httpClientHeader.headers);
  }
}
