<!-- <h4 color="primary" class="dialog-headline"><p>Create user</p></h4> -->
<div aria-hidden="true">
  <div class="dialog-headline">
    <mat-label style="float:left;">{{receivedData.headerTitle}}</mat-label>
    <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
  </div>

  <mat-dialog-content class="mat-typography" style="padding-top: 20px;">

    <form #storeModalForm="ngForm" autocomplete="off" data-keyboard="false" data-backdrop="static" aria-hidden="true">

      <!-- commented organization -->
      <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
          <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <mat-select placeholder="Select Organization"
                (keydown)="onKeydown($event)" 
                [disableOptionCentering]="true"
                (selectionChange)="onOrganizationChange(Store.organizationId)" #organization="ngModel"
                name="organization" [(ngModel)]="Store.organizationId" required>
                <mat-option *ngFor="let eachOrg of organizationArray" [value]="eachOrg.organizationId">
                  {{ eachOrg.name }}
                </mat-option>
              </mat-select>



              <mat-error *ngIf="organization.invalid && (organization.dirty || organization.touched)"
                class="invalid-feedback">
                Please select organization
              </mat-error>

            </mat-form-field>

          </div>
        </div>
      </div>


      <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Pharmacy Name" name="storeName" #storeName="ngModel" id="storeName"
                (keydown.enter)="$event.preventDefault()" [(ngModel)]="Store.storeName" required>
              <mat-error *ngIf="storeName.invalid && (storeName.dirty || storeName.touched)" class="invalid-feedback">
                Please enter store name
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Pharmacy NPINo" name="storeNo" #storeNo="ngModel" id="storeNo"
                [(ngModel)]="Store.NPINo" required maxlength="10" minlength="10" mask='0000000000'
                (keydown.enter)="$event.preventDefault()" [disabled]="receivedData.headerTitle=='Update Pharmacy'"
                (blur)="checkNPINoExists()">
              <mat-error *ngIf="storeNo.invalid && (storeNo.dirty || storeNo.touched)" class="invalid-feedback">
                Please enter Pharmacy NPINo
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Short Name" name="shortName" #shortName="ngModel" id="shortName"
                [(ngModel)]="Store.pseudoName" required (keypress)="AllowAlphaNumericChar($event)"
                (keydown.enter)="$event.preventDefault()" (blur)="checkShortNameExists()"
                [disabled]="receivedData.headerTitle=='Update Pharmacy'" appLowercase>
              <mat-error *ngIf="shortName.invalid && (shortName.dirty || shortName.touched)" class="invalid-feedback">
                Please enter short name
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 22px;">
            <mat-checkbox [(ngModel)]="Store.isActive" name="isEnabled" class="custom-checkbox"
              (keydown.enter)="$event.preventDefault()">
              Enabled
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
          <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Address 1." name="address1" #address1="ngModel" id="address1"
                (keydown.enter)="$event.preventDefault()" [(ngModel)]="Store.address1" required appTrimSpaceInput>
              <mat-error *ngIf="address1.invalid && (address1.dirty || address1.touched)" class="invalid-feedback">
                Please enter Address 1
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
          <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Address 2" name="address2" #address2="ngModel" id="address2"
                (keydown.enter)="$event.preventDefault()" [(ngModel)]="Store.address2" appTrimSpaceInput>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Div For State, City, and Zip --> <!--EPPOS-3755-->
        <div fxLayout="row wrap">
          <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

            <!-- Zip Code Field --><!--EPPOS-3826-->
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field>
                <input matInput placeholder="Zip Code" name="zipCode" #zipCode="ngModel" id="zipCode"
                  (keydown.enter)="$event.preventDefault()" (blur)="fetchStateAndCity()"
                  [(ngModel)]="Store.zipCode" required maxlength="5" minlength="5" mask="00000">
                <mat-error *ngIf="zipCode.invalid && (zipCode.dirty || zipCode.touched)" class="invalid-feedback">
                  Please enter zip Code
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

            <!-- State Field -->
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field>
                <input matInput placeholder="Select State" aria-label="State" [matAutocomplete]="autoState"
                  (keydown.enter)="$event.preventDefault()" [formControl]="stateCtrl" required />
                <button mat-button matSuffix mat-icon-button aria-label="Clear" matTooltip="Clear" *ngIf="stateCtrl.value"
                  (click)="ClearState()">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #autoState="matAutocomplete" (optionSelected)="loadCityForCurrentState('')">
                  <mat-option *ngFor="let state of filteredState | async" [value]="state.stateName"
                    (keydown.enter)="$event.preventDefault()" [disabled]="state.stateName === 'No Matching State Found'">
                    <span>{{ state.stateName }}</span>
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="stateCtrl.invalid && (stateCtrl.dirty || stateCtrl.touched)" class="invalid-feedback">
                  Please select a State
                </mat-error>
              </mat-form-field>
            </div>

            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

            <!-- City Field -->
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field>
                <input matInput placeholder="Select City" aria-label="City" [matAutocomplete]="autoCity"
                  (keydown.enter)="$event.preventDefault()" [formControl]="cityCtrl" required />
                <button mat-button matSuffix mat-icon-button aria-label="Clear" matTooltip="Clear" *ngIf="cityCtrl.value"
                  (click)="ClearCity()">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #autoCity="matAutocomplete" (optionSelected)="OnCitySelection()">
                  <mat-option *ngFor="let city of filteredCity | async" [value]="city.name"
                    (keydown.enter)="$event.preventDefault()" [disabled]="city.name === 'No Matching City Found'">
                    <span>{{ city.name }}</span>
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="cityCtrl.invalid && (cityCtrl.dirty || cityCtrl.touched)" class="invalid-feedback">
                  Please select City
                </mat-error>
              </mat-form-field>
            </div>

          </div>
        </div>
      <!-- Div For State, City, and Zip -->

      <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Owner" name="owner" #owner="ngModel" id="owner" [(ngModel)]="Store.owner"
                (keydown.enter)="$event.preventDefault()" required appTrimSpaceInput>
              <mat-error *ngIf="owner.invalid && (owner.dirty || owner.touched)" class="invalid-feedback">
                Please enter owner
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Website" name="website" #website="ngModel" id="wbsite" type="url"
                (keydown.enter)="$event.preventDefault()" [(ngModel)]="Store.website"
                pattern="[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)">
              <!-- <mat-error *ngIf="( (website.dirty || website.touched)) " class="invalid-feedback">
                Please enter website
              </mat-error> -->
              <mat-error *ngIf="website.invalid && (website.dirty || website.touched) && website.errors?.pattern"
                class="invalid-feedback">
                Please enter valid website
              </mat-error>
            </mat-form-field>

          </div>
        </div>
      </div>

      <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Contact Name" name="contactName" #contactName="ngModel" id="contactName"
                (keydown.enter)="$event.preventDefault()" [(ngModel)]="Store.contactName" required appTrimSpaceInput>
              <mat-error *ngIf="contactName.invalid && (contactName.dirty || contactName.touched)"
                class="invalid-feedback">
                Please enter contact Name
              </mat-error>
            </mat-form-field>

          </div>
          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Contact Number" name="contactNumber" #contactNumber="ngModel"
                (keydown.enter)="$event.preventDefault()" id="contactNumber" [(ngModel)]="Store.contactNo" required
                minlength="10" mask='(000)000-0000'>
              <mat-error *ngIf="contactNumber.invalid && (contactNumber.dirty || contactNumber.touched)"
                class="invalid-feedback">
                Please enter contact Number
              </mat-error>
            </mat-form-field>

          </div>
        </div>
      </div>

      <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Email" name="email" #email="ngModel" id="email" [(ngModel)]="Store.email"
                (keydown.enter)="$event.preventDefault()" required
                pattern="^(?!.*@.*@)[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$" appTrimSpaceInput>
              <mat-error *ngIf="email.invalid && (email.dirty || email.touched) || email.errors?.pattern"
                class="invalid-feedback">
                Please enter valid email
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Fax" name="fax" #fax="ngModel" id="fax" [(ngModel)]="Store.fax" required
                (keydown.enter)="$event.preventDefault()" mask='(000)000-0000' minlength="10">
              <mat-error *ngIf="fax.invalid && (fax.dirty || fax.touched)" class="invalid-feedback">
                Please enter fax
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row wrap">
        <div fxFlex.gt-lg="70" fxFlex.gt-md="70" fxFlex.gt-xs="70" fxFlex="70" style="padding-top: 10px;">
          <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <label style="font-size: 16px; font-weight: bold; text-align: left; color: #0C7BB8;">Settings</label>
          </div>
        </div>
      </div>


      <!-- EPPOS-3787 and EPPOS-3788 -->
      <div fxLayout="row" fxLayoutGap="68px" style="padding-top: 10px;">
        <mat-checkbox class="custom-checkbox" name="allowInfoLog" [(ngModel)]="Store.isInfoLog"
          (keydown.enter)="$event.preventDefault()">
          Allow Verbose Logs <!--EPPOS-3886-->
        </mat-checkbox>

        <mat-checkbox class="custom-checkbox" name="isDefaultItems" [(ngModel)]="Store.allowDefaultItems"
          (keydown.enter)="$event.preventDefault()">
          Add Default Quick Add Items
        </mat-checkbox>
      </div>
      <!-- End EPPOS-3787 and EPPOS-3788 -->

      <!-- Applications -->
      <div fxLayout="row wrap" style="padding-top: 20px;">
        <div fxFlex.gt-lg="70" fxFlex.gt-md="70" fxFlex.gt-xs="70" fxFlex="70" style="padding-top: 10px;">
          <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <label style="font-size: 16px; font-weight: bold; text-align: left; color: #0C7BB8;"
              (keydown.enter)="$event.preventDefault()">Applications</label>
          </div>
        </div>
      </div>

      <div fxLayout="row wrap">

        <div fxFlex.gt-lg="70" fxFlex.gt-md="70" fxFlex.gt-xs="70" fxFlex="70" style="padding-top: 10px;">
          <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <mat-checkbox [(ngModel)]="Store.isEdiv2" name="ediV2" class="custom-checkbox"
              (keydown.enter)="$event.preventDefault()">
              EDI CLOUD ?
            </mat-checkbox>
          </div>
          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="20" fxFlex="20"></div>
          <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <mat-checkbox [(ngModel)]="Store.isMpos" name="ePrimePOS" class="custom-checkbox"
              (keydown.enter)="$event.preventDefault()">
              PRIMERX POS ?
            </mat-checkbox>
          </div>
          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="20" fxFlex="20"></div>
          <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <mat-checkbox [(ngModel)]="Store.isPrimeRxCloud" (change)="setDefaultAdminForPrimerx()" name="ePrimeRx" class="custom-checkbox"
              (keydown.enter)="$event.preventDefault()">
              PRIMERX CLOUD ?
            </mat-checkbox>

          </div>

        </div>
      </div>

      <div fxLayout="row wrap" *ngIf="Store.isMpos" style="padding-top: 10px;padding-bottom: 10px;">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-checkbox [(ngModel)]="Store.enableSuggestiveSelling" name="isSuggSelling" class="custom-checkbox">
              Suggestive Selling
            </mat-checkbox>
          </div>
          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100"></div>
        </div>
      </div>
      <div fxLayout="row wrap" *ngIf="Store.isMpos" style="padding-top: 10px;">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
          <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="No.Of terminal(s)" name="noOfTerminals" #noOfTerminals="ngModel"
                (keydown.enter)="$event.preventDefault()" type="number" min="1" id="noOfTerminals"
                [(ngModel)]="Store.terminal" required (keypress)="AllowOnlyNumber($event)">
              <mat-error *ngIf="noOfTerminals.invalid && (noOfTerminals.dirty || noOfTerminals.touched)"
                class="invalid-feedback">
                Please enter no of terminals
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
          <div fxFlex.gt-lg="75" fxFlex.gt-md="75" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <mat-label>Select Pay Type(s)</mat-label>
              <mat-select placeholder="Select Pay Types" #payTypeID="ngModel" name="payTypeID"
                [(ngModel)]="showPayTypeArray" required (ngModelChange)="onPayTypeSelectionChange($event)" multiple>
                <mat-option *ngFor="let payType of payTypes" [value]="payType.payType"
                  (keydown.enter)="$event.preventDefault()" [disabled]="isPayTypeDisabled(payType.payType)">
                  {{ payType.payTypeDesc }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

      </div>

      <div fxLayout="row wrap" *ngIf="Store.isMpos" style="padding-top: 10px;">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

          <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100" *ngIf="Store.isMpos">
            <button class="custom-blue-button" type="button" id="btnConfigureStation" [disabled]="!Store.terminal"
              style="width: 130px; height: 35px;font-size: 14px; font-weight: normal;text-align: center;"
              (click)="goToELO()">
              Configure Station
            </button>
          </div>

          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>


          <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"
            *ngIf="Store.isMpos && Store.NPINo != '' && Store.NPINo != undefined">

            <button class="custom-blue-button" type="button" id="btnCustomPay"
              [disabled]="Store.NPINo=='' || Store.NPINo === undefined"
              style="width: 140px; height: 35px; font-weight: normal;" (click)="openPayTypeMasterModal()">
              Add Custom Paytype
            </button>
          </div>
        </div>
      </div>

      <div fxLayout="row wrap" *ngIf="Store.isMpos && Store.NPINo != '' && Store.NPINo != undefined"
        style="padding-top: 30px;">
        <div fxLayout="row wrap" *ngIf="customPayTypes.length > 0">
          <div fxFlex.gt-lg="70" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="70">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <label style="font-size: 16px; font-weight: bold; text-align: left; color: #0C7BB8;"
                (keydown.enter)="$event.preventDefault()">Custom Pay
                Types</label>
            </div>

          </div>

        </div>

        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 10px;">
          <div class="form-group">
            <div class="form-group" *ngFor="let payType of customPayTypes; let count = index">
              <div>
                <div>
                  <!-- <mat-card-title class="text-info">Pay Type {{ count + 1 }}</mat-card-title> -->
                  <label style="font-size: 15px; font-weight: regular; text-align: left; color: #424244;"
                    (keydown.enter)="$event.preventDefault()">Pay Type {{
                    count + 1 }}</label>
                </div>
                <div fxLayout="row wrap">
                  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" class="margin-left">
                    <div fxFlex.gt-lg="2" fxFlex.gt-md="2" fxFlex.gt-xs="100" fxFlex="100" class="margin-left"></div>
                    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                      <mat-form-field>
                        <input matInput placeholder="Pay Type Description" name="payTypeDesc{{ count }}"
                          (keydown.enter)="$event.preventDefault()" #payTypeDesc="ngModel" id="payTypeId"
                          [(ngModel)]="payType.payTypeDesc" required>

                      </mat-form-field>
                      <mat-error
                        *ngIf="payTypeDesc.invalid && (payTypeDesc.dirty || payTypeDesc.touched) && payType.payTypeDesc.toString().trim().length==0"
                        class="invalid-feedback">
                        Please enter Pay Type Description
                      </mat-error>
                    </div>
                    <!-- <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
                          <button style="float: right;" mat-raised-button color="primary" type="button"
                          (click)="updateCustomPay(payType)">Update</button>
                        </div> -->

                  </div>
                </div>
              </div>
              <!-- <mat-card class="mat-elevation-z24">
                    <mat-card-content>

                    </mat-card-content>
                </mat-card> -->
            </div>
            <!-- <div>
                <div class="col-md-9"></div>
                <div class="col-md-3" style="padding-right: 5px;">
                    <button style="float: right;" mat-raised-button color="primary" type="button" [disabled]="Store.NPINo == '' || Store.NPINo == undefined"
                        (click)="openPayTypeMasterModal()">Add Pay Type</button>
                </div>
            </div> -->
          </div>
        </div>
      </div>

      <div fxLayout="row wrap" *ngIf="Store.isPrimeRxCloud" style="padding-top: 30px;">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="70" fxFlex="70" style="padding-top: 10px;">
          <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <label style="font-size: 16px; font-weight: bold; text-align: left; color: #0C7BB8;"
              (keydown.enter)="$event.preventDefault()">PRIMERX CLOUD
              Details</label>
          </div>

        </div>
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Admin First Name" name="AdminFirstName" #AdminFirstName="ngModel"
                (keydown.enter)="$event.preventDefault()" id="AdminFirstName"
                [(ngModel)]="Store.primeRxCloudData.adminFirstName" required
                [disabled]="receivedData.headerTitle=='Update Pharmacy'" appTrimSpaceInput>
              <mat-error *ngIf="AdminFirstName.invalid && (AdminFirstName.dirty || AdminFirstName.touched)"
                class="invalid-feedback">
                Please enter Admin First Name
              </mat-error>
            </mat-form-field>
          </div>

          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Admin Last Name" name="AdminLastName" #AdminLastName="ngModel"
                (keydown.enter)="$event.preventDefault()" id="AdminLastName"
                [(ngModel)]="Store.primeRxCloudData.adminLastName" required
                [disabled]="receivedData.headerTitle=='Update Pharmacy'" appTrimSpaceInput>
              <mat-error *ngIf="AdminLastName.invalid && (AdminLastName.dirty || AdminLastName.touched)"
                class="invalid-feedback">
                Please enter Admin Last Name
              </mat-error>
            </mat-form-field>

          </div>
        </div>
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100"
          *ngIf="receivedData.headerTitle!='Update Pharmacy'" style="padding-top: 20px;">
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Admin User Name" name="AdminUserName" #AdminUserName="ngModel"
                (keydown.enter)="$event.preventDefault()" id="AdminUserName"
                [(ngModel)]="Store.primeRxCloudData.adminUserName" required matTooltip="By default primerx cloud admin username is admin"
                readonly="true" appTrimSpaceInput autocomplete="new-password">
              <mat-error *ngIf="AdminUserName.invalid && (AdminUserName.dirty || AdminUserName.touched)"
                class="invalid-feedback">
                Please enter Admin User Name
              </mat-error>
            </mat-form-field>

          </div>

          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">

            <mat-form-field>
              <input matInput placeholder="Password" name="password" #password="ngModel" id="password"
                (keydown.enter)="$event.preventDefault()" [(ngModel)]="Store.primeRxCloudData.adminPassword" required
                [type]="hide ? 'password' : 'text'" autocomplete="new-password">

              <!-- <mat-icon class="pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' :
                'visibility'}}
              </mat-icon> -->

              <img class="pointer" *ngIf="!hide" width="24px" style="padding-bottom: 10px;text-align: right;" matSuffix
                src="assets/images/icons/icon-visibility-ON.png" alt="Toggle Password Visibility"
                (click)="hide = !hide">
              <img class="pointer" *ngIf="hide" width="24px" style="padding-bottom: 10px;text-align: right;" matSuffix
                src="assets/images/icons/icon-visibility-OFF.png" alt="Toggle Password Visibility"
                (click)="hide = !hide">


            </mat-form-field>

            <mat-password-strength (onStrengthChanged)="onPasswordStrengthChanged($event);" [externalError]="true"
              (keydown.enter)="$event.preventDefault()" [min]="8" [max]="10" #passwordComponent
              [password]="password.value">
            </mat-password-strength>

            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                [hideToggle]="showError">
                <mat-expansion-panel-header>
                  <mat-panel-description>
                    <mat-icon *ngIf="!panelOpenState">arrow_forward</mat-icon>
                    <mat-icon *ngIf="panelOpenState">arrow_downward</mat-icon>
                    Expand to see errors
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-password-strength-info [passwordComponent]="passwordComponent">
                </mat-password-strength-info>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 20px;">
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput (keypress)="AllowOnlyNumber($event)" placeholder="Pharmacy NABP No." name="NABPNo"
                (keydown.enter)="$event.preventDefault()" #NABPNo="ngModel" id="NABPNo"
                [(ngModel)]="Store.primeRxCloudData.pharmacyNABPNum" required maxlength="7"
                [disabled]="receivedData.headerTitle=='Update Pharmacy'" appTrimSpaceInput>
              <mat-error *ngIf="NABPNo.invalid && (NABPNo.dirty || NABPNo.touched)" class="invalid-feedback">
                Please enter Pharmacy NABP No.
              </mat-error>
            </mat-form-field>

          </div>

          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="Pharmacy Registration No." name="PharmacyRegistrationNum"
                (keydown.enter)="$event.preventDefault()" #PharmacyRegistrationNum="ngModel"
                id="PharmacyRegistrationNum" [(ngModel)]="Store.primeRxCloudData.pharmacyRegistrationNum"
                [disabled]="receivedData.headerTitle=='Update Pharmacy'" appTrimSpaceInput>
              <!-- <mat-error
                *ngIf="PharmacyRegistrationNum.invalid && (PharmacyRegistrationNum.dirty || PharmacyRegistrationNum.touched)"
                class="invalid-feedback">
                Please enter Pharmacy Registration Number
              </mat-error> -->
            </mat-form-field>

          </div>
        </div>
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 20px;">
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">

            <mat-form-field>
              <input matInput placeholder="Pharmacy DEA Registration No." name="PharmacyDEARegistration"
                (keydown.enter)="$event.preventDefault()" #PharmacyDEARegistration="ngModel" id="DEA"
                [(ngModel)]="Store.primeRxCloudData.pharmacyDEARegistration" required appTrimSpaceInput
                [disabled]="receivedData.headerTitle=='Update Pharmacy'">
              <mat-error
                *ngIf="PharmacyDEARegistration.invalid && (PharmacyDEARegistration.dirty || PharmacyDEARegistration.touched)"
                class="invalid-feedback">
                Please enter Pharmacy DEA Registration No.
              </mat-error>
            </mat-form-field>

          </div>

          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>

              <mat-select placeholder="Select Master DB" #masterDbName="ngModel" name="masterDbName"
                [(ngModel)]="Store.primeRxCloudData.masterDbName" required (keydown.enter)="$event.preventDefault()"
                [disabled]="receivedData.headerTitle=='Update Pharmacy'">
                <mat-option *ngFor="let eachName of masterDBArray" [value]="eachName.name">
                  {{ eachName.name }}
                </mat-option>
              </mat-select>

              <mat-error *ngIf="masterDbName.invalid && (masterDbName.dirty || masterDbName.touched)"
                class="invalid-feedback">
                Please select Master DB Name
              </mat-error>
            </mat-form-field>

          </div>
        </div>
      </div>

      <div fxLayout="row wrap" *ngIf="Store.isPrimeRxCloud" style="padding-top: 10px;">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 20px;">
          <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-checkbox [(ngModel)]="Store.isPrimeRxCloudEmptyPharmacy" name="isPrimeRxCloudEmptyPharmacy" [disabled]="receivedData.headerTitle=='Update Pharmacy'"
              class="custom-checkbox" (change)="givePromptForEmptyPharmacy()">
              PrimeRxCloud Empty Pharmacy ?
            </mat-checkbox>
          </div>
          <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

        </div>
      </div>
    </form>


  </mat-dialog-content>

  <hr>

  <mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="center center" style="margin: 10px;">

    <button mat-flat-button color="warn" (click)="DeleteStore(Store)"
      *ngIf="receivedData.headerTitle=='Update Pharmacy'" [disabled]="storeModalForm.invalid"
      style="background-color: #6C747E;width:
        80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;">Delete</button>

    <button mat-stroked-button mat-dialog-close style="background-color: #6C747E;width:
        80px;height: 40px;color: #FFFFFF; border-radius:
        4px;font-size: 14px;text-align: center;line-height: 20px;">
      Close
    </button>

    <button mat-flat-button color="primary" (click)="CreatePharmacy(Store)"
      *ngIf="receivedData.headerTitle=='Create Pharmacy'" style="background-color: #F59120;width:
        80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
      [disabled]="isFormInvalid()">Create</button>

    <button mat-flat-button color="primary" (click)="UpdateStore(Store)"
      *ngIf="receivedData.headerTitle=='Update Pharmacy'" style="background-color: #F59120;width:
        80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
      [disabled]="isFormInvalid()">Update</button>


  </mat-dialog-actions>
</div>
