import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ediconstant } from 'src/app/constant/ediconstant';
import { FileDownloadRequest } from 'src/app/models/RequestModel/FileDownloadRequest';
import { BaseHttpService } from 'src/app/utility/BaseHttpService';

@Injectable({
  providedIn: 'root'
})

export class FileDownloadService {

  constructor(private baseHttpService: BaseHttpService) { }

  getFileDowloadData(fileData: FileDownloadRequest) {
    return this.baseHttpService.post<any>(ediconstant.getfileDownloadUrl, fileData, ediconstant.httpClientHeader.headers);
  }

  getContractNames(): Observable<any> {
     return this.baseHttpService.get<any>(ediconstant.getContractNamesUrl, ediconstant.httpClientHeader.headers);
  }

  getPODetails(fileData: FileDownloadRequest): Observable<any> {
    return this.baseHttpService.post<any>(ediconstant.getPODetails, fileData, ediconstant.httpClientHeader.headers);
  }
}
