import { Injectable } from '@angular/core';
import { BaseHttpService } from '../utility/BaseHttpService';
import { constant } from '../constant/constant';
import { ApiResponse } from '../models/ApiResponse';

@Injectable({
  providedIn: 'root'
})
export class PrimerxCloudPharmacyService {

  constructor(private baseHttpService: BaseHttpService) { }

  // Search pharmacy with retry logic
  SearchPharmacy(pharmacyRequest: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.PHARMACY_SERVICE_URL + constant.GET_PHARMACY, pharmacyRequest, constant.httpClientHeader.headers);
  }

  // Insert pharmacy record with retry logic
  Insert(pharmacyRequest: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.PHARMACY_SERVICE_URL + constant.INSERT_PHARMACY, pharmacyRequest, constant.httpClientHeader.headers);
  }

  // Update pharmacy record with retry logic
  Update(pharmacyRequest: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.PHARMACY_SERVICE_URL + constant.UPDATE_PHARMACY, pharmacyRequest, constant.httpClientHeader.headers);
  }

  // Delete pharmacy record with retry logic
  Delete(pharmacyRequest: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.PHARMACY_SERVICE_URL + constant.DELETE_PHARMACY, pharmacyRequest, constant.httpClientHeader.headers);
  }

  // Get Master DB Name with retry logic
  GetMasterDBName() {
    return this.baseHttpService.get<ApiResponse<any>>(constant.PHARMACY_SERVICE_URL + constant.GET_MASTERDB, constant.httpClientHeader.headers);
  }

  // Load cities for the current state with retry logic
  loadCityForCurrentState(cityRequest: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.PHARMACY_SERVICE_URL + constant.GET_CITIES, cityRequest, constant.httpClientHeader.headers);
  }

  // Restart migration process with retry logic
  RestartMigration(migrationRequest: any) {
    return this.baseHttpService.post<ApiResponse<any>>(constant.PHARMACY_SERVICE_URL + constant.RESTART_DB_MIGRATION, migrationRequest, constant.httpClientHeader.headers);
  }
}
