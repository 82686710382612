import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdiUserAccess } from 'src/app/models/EdiUserAccess';
import { RoleService } from 'src/app/services/role.service';
import { EdiUserService } from 'src/app/services/edi_user.service';
import { EdiUtilityService } from 'src/app/services/edi-utility.service';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { Store } from 'src/app/models/Store';
import { Vendor } from 'src/app/models/Vendor';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { EDIStoreService } from 'src/app/services/edi_store.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.css']
})

export class UserModalComponent implements OnInit {

  user: any = {};
  roleArray: any[] = [];
  hide: boolean = true;
  hideConfirm: boolean = true;
  showDetails: boolean = true;
  showError: boolean = false;
  panelOpenState: boolean = false;
  isPasswordStrong: boolean = false;
  deleteUserData: boolean = false;
  pseudoName: any = "";//used for showing in the modal form 
  NPINo: any = "";//used for showing in the modal form 

  modal_title!: string;
  headerTitle!: string;
  userAccessData!: EdiUserAccess;

  npiControl = new FormControl('');
  filteredOptions: Observable<Store[]> | undefined;

  vendorNameControl = new FormControl('');
  filteredVendorOptions: Observable<Vendor[]> | undefined;
  storeArray!: Store[];


  displayNPINos(storeData: Store): string {
    return storeData && storeData.NPINo ? storeData.NPINo : '';
  }

  selectedNPINo: string = "";
  existingMappedVendor: Vendor[] = [];
  vendorMasterArray: Vendor[] = [];
  vendorInformation: Vendor = {
    mId: '',
    NPINo: '',
    vendorId: '',
    vendorCode: '',
    vendorName: '',
    vendorAccount:'',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phoneOff: '',
    cellNo: '',
    faxNo: '',
    webAddress: '',
    emailAddress: '',
    isActive: false,
    POExpiresInDays: '',
    overdueTime: '',
    process810: false,
    isDelete: false,
    pseudoName: '',
    priceQualifier: '',
    costQualifier: '',
    saleQualifier: '',
    isEPO: false,
    salePriceUpdate: false,
    vendorCostPrice: false,
    autoClose: false,
    updatePrice832: false,
    ackPriceUpdate: false,
    reduceSellingPriceWith832File: false,
    processSwitch810: '',
    processSwitch832: '',
    processSwitch835: '',
    processSwitch855: ''
  };


  constructor(@Inject(MAT_DIALOG_DATA) public receivedData: any, private dialogService: MatDialogRef<UserModalComponent>, private utilityService: EdiUtilityService, private roleService: RoleService, private userService: EdiUserService, private vendorService: VendorService, private storeService: EDIStoreService, private tokenStorageService: TokenStorageService) {
    this.userAccessData = userService.getEdiUsersAccessData();
    if (receivedData.userData) {
      this.user = receivedData.userData;
      this.user.confirmPassword = receivedData.userData.password;
    }
    // this.user=this.tokenStorageService.getUser();

    // if (!this.userAccessData) {
    //     this.userAccessData = {} as any;
    // }

    // this.userAccessData.NPINo = this.user.NPINo;
    // this.userAccessData.pseudoName = this.user.pseudoName;

    this.loadAllNPINo();
  }

  ngOnInit(): void {
    //console.log(this.receivedData);
    this.pseudoName = sessionStorage.getItem("pseudoName");
    this.NPINo = sessionStorage.getItem("NPINo");
    this.user.NPINo = this.NPINo;
  }

  ngAfterViewInit() {
    //calling role api to load in role dropdown
    this.getRoles();
  }
  togglePanel() {
    this.panelOpenState = !this.panelOpenState;
  }


  onPasswordStrengthChanged(event: any) {
    if (event == 100)
      this.isPasswordStrong = true;

  }

  getRoles() {

    let roleFilter = {
      isActive: true
    };
    this.roleService.getRoles(roleFilter).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedUserResponse = JSON.parse(response.data);

          this.roleArray = receivedUserResponse;

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching the role data")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  createUser() {

    this.user.userName = this.user.userName + "@" + this.pseudoName;
    this.user.NPINo = this.vendorInformation.NPINo;
    this.userService.createUsers(this.user).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          this.utilityService.showAlert(
            constant.KEY_SUCCESS,
            constant.ALERT_CREATE_TITLE,
            "User created successfully"
          ).subscribe(result => {

            //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
            this.dialogService.close(true);
          });

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error occurred while creating the user data")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  updateUser() {
    this.user.modifiedBy = sessionStorage.getItem("userName");
    this.userService.updateUsers(this.user).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.utilityService.showAlert(
            constant.KEY_SUCCESS,
            constant.ALERT_UPDATE_TITLE,
            "User updated successfully"
          ).subscribe(result => {
            //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
            this.dialogService.close(true)
          });
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while updating the user data")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  deleteUser() {
    let passData = {
      promptTitle: "Are you sure ?",
      promptMessage: "You won't be able to revert this!",
    };

    this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
      if (result == true) {
        this.deleteUserData = true;
        this.userService.deleteUsers(this.user).subscribe({
          next: (response) => {
            if (response.result == ResponseStatus.SUCCESS) {

              this.utilityService.showAlert(
                constant.KEY_SUCCESS,
                constant.ALERT_DELETE_TITLE,
                "User deleted successfully"
              ).subscribe(result => {

                //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                this.dialogService.close(true)
              });
            } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

              this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error occurred while deleting the user data")
            }
          },
          error: (errorResponse) => {
            console.log(errorResponse);
            this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
          }
        });
      }
    });
  }

  roleChange(event: any) {
    console.log(event)
    var countryObj = this.roleArray.find(stat => stat.roleName == event);

    this.user.roleId = countryObj.roleId;
    this.user.role = countryObj.roleName
    this.user.roleName = countryObj.roleName
  }


  loadAllNPINo() {

    this.storeService.getAllNPINo().subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          var receivedVendorResponse = JSON.parse(response.data);
          this.storeArray = receivedVendorResponse;
          this.setValueToNPIDropdown();
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching list of NPINo")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  setValueToNPIDropdown() {

    this.filteredOptions = this.npiControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const npi = typeof value === 'string' ? value : value?.npi;
        var retFilter = npi ? this.filterNPINos(npi as string) : this.storeArray.slice();
        return retFilter;
      }),
    );
  }

  private filterNPINos(name: string): Store[] {
    const filterValue = name.toLowerCase();

    var retNPI = this.storeArray.filter(option => option.NPINo.toLowerCase().includes(filterValue));
    return retNPI;

  }

  AllowOnlyCharacter(event: any) {
    return this.utilityService.AllowOnlyCharacter(event)
  }

  setNPINoToModel(event: any) {
    this.vendorInformation.NPINo = event.option.value.NPINo;

    this.selectedNPINo = event.option.value.NPINo;//adding the value of selected npi to the field

    //THIS WILL GET THE VENDORS AND THEN DIFFERENCE OF MAPPED VENDORS
    this.getUnMappedVendors(this.vendorInformation.NPINo);

  }

  getUnMappedVendors(receivedNPINo: string) {

    let passVendorInfo = {
      NPINo: receivedNPINo
    };

    this.vendorService.getVendorData(passVendorInfo).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedVendorResponse = JSON.parse(response.data);
          this.existingMappedVendor = receivedVendorResponse;

          //now getting the diffrence between the master vendor and mapped  vendor
          this.vendorMasterArray = this.vendorMasterArray.filter(unmappedVendor =>
            !this.existingMappedVendor.some(mappedVendor => (unmappedVendor.vendorCode == mappedVendor.vendorCode)));

          //AFTER GETTING THE DIFFERENCE AGAIN CALLING THE SET METHOD FOR ATTACHING THE FILTERED VENDORS
          this.setValueToVendorDropdown();

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching mapped vendors data")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });

  }

  setValueToVendorDropdown() {

    this.filteredVendorOptions = this.vendorNameControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const vendorCode = typeof value === 'string' ? value : value?.vendorCode;
        var retFilterVendor = vendorCode ? this.filterVendorNames(vendorCode as string) : this.vendorMasterArray.slice();
        return retFilterVendor;
      }),
    );
  }

  private filterVendorNames(name: string): Vendor[] {
    const filterValue = name.toLowerCase();

    var retFilteredVendor = this.vendorMasterArray.filter(option => option.vendorCode.toLowerCase().includes(filterValue));
    return retFilteredVendor;

  }
}