import { Injectable } from '@angular/core';
import { BaseHttpService } from '../utility/BaseHttpService'; // Import BaseHttpService
import { constant } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})

export class StoreAdminService {

  constructor(private baseHttpService: BaseHttpService) { }

  // GetStoreAdminOnLoad
  GetStoreAdminOnLoad() {
    return this.baseHttpService.get<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.GET_ALL_STORES_ADMIN_ON_LOAD), constant.httpClientHeader.headers);
  }

  // GetStoreAdminOnLoadPagination
  GetStoreAdminOnLoadPagination(pageData: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.GET_ALL_STORES_ADMIN_ON_LOAD), pageData, constant.httpClientHeader.headers);
  }

  // SearchStoreAdmin
  SearchStoreAdmin(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.GET_STORE_ADMIN_ON_SEARCH), store, constant.httpClientHeader.headers);
  }

  // GetStoreAdminByNPI
  GetStoreAdminByNPI(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.GET_STORE_ADMIN_BY_NPI), store, constant.httpClientHeader.headers);
  }

  // Insert
  Insert(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.INSERT_STORE_ADMIN), store, constant.httpClientHeader.headers);
  }

  // Update
  Update(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.UPDATE_STORE_ADMIN), store, constant.httpClientHeader.headers);
  }

  // Delete
  Delete(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.DELETE_STORE_ADMIN), store, constant.httpClientHeader.headers);
  }
}
