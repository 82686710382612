import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError, switchMap, filter, take, retryWhen, mergeMap, timer } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { UserService } from './user.service';

const TOKEN_HEADER_KEY = 'Authorization';
const TOKEN_TYPE_PROVIDER = 'Bearer';

@Injectable()

export class HttpAuthInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private tokenService: TokenStorageService, private userService: UserService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let authReq = req;
    const token = this.tokenService.getToken();
    if (token != null) {


      // if(!authReq.url.includes('oauth/token') && !authReq.url.includes('ProviderConfiguration') && !authReq.url.includes('refresh-token') && !authReq.url.includes('GetPharmacy'))
      if(!authReq.url.includes('oauth/token') && !authReq.url.includes('primecomm') && !authReq.url.includes('refresh-token'))
      {
        authReq = this.addTokenHeader(req, token);
      }
    }
    return next.handle(authReq).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && !authReq.url.includes('/storeConfig/auth/user/AuthenticateByPseudoName') && error.status === 401) {
        return this.handle401Error(authReq, next);
      }
      return throwError(()=>error);
    }));
  }


  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.tokenService.getRefreshToken();
      if (token)
        //Add ref token api call here
        return this.userService.refreshToken(token).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.tokenService.saveToken(token.token);
            this.refreshTokenSubject.next(token.refreshToken);

            return next.handle(this.addTokenHeader(request, token.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;

            this.tokenService.signOut();
            return throwError(()=>err);
          })
        );
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    /* for Spring Boot back-end */
    // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    /* for Node.js Express back-end */
    let tokenHeader = TOKEN_TYPE_PROVIDER + " " + token;
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, tokenHeader)
    });
  }
}


export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptorService, multi: true }
];
