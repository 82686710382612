import { Injectable } from '@angular/core';
import { BaseHttpService} from '../utility/BaseHttpService'; // Import the base service
import { constant } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class MMSAdminService {

  constructor(private baseHttpService: BaseHttpService) { }

  GetMMSAdminOnLoad() {
    return this.baseHttpService.get<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.GET_ALL_MMS_ADMIN_ON_LOAD), constant.httpClientHeader.headers);
  }

  GetMMSAdminOnLoadPagination(pageData: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.GET_ALL_MMS_ADMIN_ON_LOAD), pageData, constant.httpClientHeader.headers);
  }

  SearchMMSAdmin(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.GET_MMS_ADMIN_ON_SEARCH), store, constant.httpClientHeader.headers);
  }

  Insert(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.INSERT_MMS_ADMIN), store, constant.httpClientHeader.headers);
  }

  Update(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.UPDATE_MMS_ADMIN), store, constant.httpClientHeader.headers);
  }

  Delete(store: any) {
    return this.baseHttpService.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.DELETE_MMS_ADMIN), store, constant.httpClientHeader.headers);
  }
}
