import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

export enum CallType {
  VENDOR_INFO = "VendorInformation",
  VENDOR_CONNECTION = "VendorConnection",
  VENDOR_FIELDS = "VendorFields"
}

export class ediconstant {

  //http header variable
  public static httpClientHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  // Host/Ports

  public static baseURL: string = environment.baseURL;

  // //for localhost use this
  // public static fileDownloadTag: string = ":9030";
  // public static fileParseErrorTag: string = ":9030";
  // public static userTag: string = ":9030";
  // public static roleTag: string = ":9030";
  // public static vendorTag: string = ":9030";
  // public static storeTag: string = ":9030";
  // public static vendorPriceUpdateTag: string = "9030";
  // public static vendorPollerTag: string = "9030";
  // public static vendorOrderTag: string = "9030";
  // public static : string = ":9030";

  //for prod server
  public static fileDownloadTag: string = "/ediFileDownloadService";
  public static fileParseErrorTag: string = "/ediFileStatusService";
  public static userTag: string = "/ediUserService";
  public static roleTag: string = "/ediRoleService";
  public static vendorTag: string = "/ediVendorService";
  public static storeTag: string = "/ediStoreService";
  public static vendorPriceUpdateTag: string = "/edivendorpriceupdateservice";
  public static vendorPollerTag: string = "/edivendorpollerservice";
  public static vendorOrderTag: string = "/edivendororderservice";
  public static storeConfigUserTag: string = ":9030";

  //============API Urls===============

  public static getfileDownloadUrl: string = ediconstant.baseURL + ediconstant.fileDownloadTag + "/FileDownload/getFileDownloadData";
  //public static getfileDownloadUrl: string = "http://localhost:8084/FileDownload/getFileDownloadData";

  public static getContractNamesUrl: string = ediconstant.baseURL + ediconstant.fileDownloadTag + "/FileDownload/getContractNames";
  public static getfileParseErrorUrl: string = ediconstant.baseURL + ediconstant.fileParseErrorTag + "/FileStatus/getFileParseErrorData";
  //public static getfileParseErrorUrl: string = "http://localhost:8069/FileStatus/getFileParseErrorData";

  // user Urls
  public static userLoginUrl: string = ediconstant.baseURL + this.userTag + "/User/AuthenticateByPseudoName";
  //public static userLoginUrl: string = "http://localhost:8077/User/AuthenticateByPseudoName";
  public static refreshToken: string = ediconstant.baseURL + this.userTag + "/User/refresh-token";
  // public static userLoginUrl: string = ediconstant.baseURL + ediconstant.userTag + "/User/AuthenticateByPseudoName";
  public static getUsersUrl: string = ediconstant.baseURL + ediconstant.userTag + "/User/getUsers";
  //public static getUsersUrl: string = "http://localhost:8077/User/getUsers";
  public static insertUserUrl: string = ediconstant.baseURL + ediconstant.userTag + "/User/InsertUsers";
  //public static insertUserUrl: string="http://localhost:8077/User/InsertUsers";
  //http://localhost:8075/Store/getAllStores
  public static updateUserUrl: string = ediconstant.baseURL + ediconstant.userTag + "/User/UpdateUsers";
  public static deleteUserUrl: string = ediconstant.baseURL + ediconstant.userTag + "/User/DeleteUsers";
  public static getUserByUserNameUrl: string = ediconstant.baseURL + ediconstant.userTag + "/User/getUserByUserName";
  public static changeUserPasswordUrl: string = ediconstant.baseURL + ediconstant.userTag + "/User/changeUserPassword";
  public static changeUserPasswordAtLoginUrl: string = ediconstant.baseURL + ediconstant.userTag + "/User/changeUserPasswordAtLogin";


  //store config user urls
  // public static getUserFromStoreConfigUrl: string = ediconstant.baseURL + ediconstant.storeConfigUserTag + "/User/getUsersByID";

  //role url
  public static getRoleUrl: string = ediconstant.baseURL + ediconstant.roleTag + "/Role/getActiveRoles";
  //public static getRoleUrl: string="http://localhost:8073/Role/getActiveRoles";

  //vendor url
  public static createFullVendorUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/createFullMasterVendor";
  //public static createFullVendorUrl: string = "http://localhost:8067/Vendor/createFullMasterVendor";
  public static getMasterVendorUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/getMasterVendor";
  //public static getMasterVendorUrl: string = "http://localhost:8067/Vendor/getMasterVendor";
  public static getVendorUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/getAllVendorsByNPI";
  //public static getVendorUrl: string = "http://localhost:8067/Vendor/getAllVendorsByNPI";
  public static getVendorWithContractUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/getAllVendorsWithContract";
  //public static getVendorWithContractUrl: string="http://localhost:8067/Vendor/getAllVendorsWithContract";
  public static saveVendorWithContractUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/SaveVendorWithContract";
  public static checkIfContractAlreadyExists: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/CheckIfContractAlreadyExists";
  public static deleteVendorWithContractUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/DeleteVendorContract";
  public static getMasterVendorConnAndFieldsUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/getMasterVendorConnAndFields";
  public static getVendorConnAndFieldsUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/getVendorConnAndFields";
  public static getFullActiveVendorsByCodeUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/getFullActiveVendorsByCode";
  public static mapFullVendorWithNPINoUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/mapFullVendorWithNPINo";
  public static testVendorFtpConnectionUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/testVendorFtpConnection";
  public static deleteMasterVendorUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/deleteMasterVendor";
  public static updateMasterVendorUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/updateFullMasterVendor";
  public static updateVendorConnectionUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/updateVendorConnection";
  public static getFTPSchedulerUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/getFTPScheduler";
  public static saveFTPSchedulerUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/saveFTPScheduler";
  public static updateVendorInformationUrl: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/UpdateVendorInformation";
  public static deleteNPIMappedVendors: string = ediconstant.baseURL + ediconstant.vendorTag + "/Vendor/deleteNPIMappedVendors";
  //public static deleteNPIMappedVendors: string = "https://localhost:8068/Vendor/deleteNPIMappedVendors";
  //VendorPoller
  public static updateFilePollerCronScheduler: string = ediconstant.baseURL + ediconstant.vendorPollerTag + "/VendorPoller/updateCronScheduler";
  public static fileDownloadGetNowScheduler: string = ediconstant.baseURL + ediconstant.vendorPollerTag + "/VendorPoller/getNowScheduler";

  //VendorPriceUpdate
  public static updatePriceUpdateCronScheduler: string = ediconstant.baseURL + ediconstant.vendorPriceUpdateTag + "/VendorPriceUpdate/updateCronScheduler";
  public static getNowPrice832Scheduler: string = ediconstant.baseURL + ediconstant.vendorPriceUpdateTag + "/VendorPriceUpdate/RunPriceUpdateSchedulerOnce";

  //VendorOrder
  public static updateInvoiceCronScheduler: string = ediconstant.baseURL + ediconstant.vendorOrderTag + "/VendorOrder/updatePOResponse810CronScheduler";
  public static updateACKCronScheduler: string = ediconstant.baseURL + ediconstant.vendorOrderTag + "/VendorOrder/updatePOResponse855CronScheduler";
  public static updatePOCronScheduler: string = ediconstant.baseURL + ediconstant.vendorOrderTag + "/VendorOrder/updatePORequestCronScheduler";
  public static getNowOrder810Scheduler: string = ediconstant.baseURL + ediconstant.vendorOrderTag + "/VendorOrder/RunOrderUpdate810SchedulerOnce";
  public static getNowOrder855Scheduler: string = ediconstant.baseURL + ediconstant.vendorOrderTag + "/VendorOrder/RunOrderUpdate855SchedulerOnce";
  public static getPODetails: string = ediconstant.baseURL + ediconstant.vendorOrderTag + "/VendorOrder/getPurchaseOrderLog";


  //Store 
  public static getAllNPINoUrl: string = ediconstant.baseURL + ediconstant.storeTag + "/Store/getAllStores";
  //public static getAllNPINoUrl: string ="http://localhost:8075/Store/getAllStores";

  public static KEY_SUCCESS = 'SUCCESS';
  public static KEY_ERROR = 'ERROR';
  public static KEY_WARNING = 'WARNING';
  public static KEY_FAILURE = 'FAILURE';
  public static ALERT_WARNING_TITLE = 'Warning';
  public static ALERT_CREATE_TITLE = 'Created';
  public static ALERT_UPDATE_TITLE = 'Updated';
  public static ALERT_DELETE_TITLE = 'Deleted';
  public static ALERT_FAILED_TITLE = 'Failed';
  public static ALERT_SUCCESS_TITLE = 'Success';
  public static ALERT_TIMEOUT_TITLE = 'Time out';
  public static KEY_STATION_DETAILS = 'stationDetail';
  public static ALERT_ADDED_TITLE = 'Added';

  public static AWP = "AWP";
  public static CAT = "CAT";
  public static CON = "CON";
  public static DAP = "DAP";
  public static FUL = "FUL";
  public static MSR = "MSR";
  public static NET = "NET";
  public static PRO = "PRO";
  public static RES = "RES";
  public static UCP = "UCP";
  public static RTL = "RTL";
  public static RLT = "RLT";
  public static PRP = "PRP";
  public static INV = "INV";
  public static PBQ = "PBQ";
  public static BCH = "BCH";
  public static RESM = "RESM";

  public static qualifiers: string[] = ["AWP", "CAT", "CON", "DAP", "FUL", "MSR", "NET", "PRO", "RES", "UCP", "RTL", "RLT", "PRP", "INV", "PBQ", "BCH", "RESM"]
  public static fileOptions: string[] =['Disable', 'DownloadAndProcess']
}

