<div class="dialog-headline">
    <mat-label style="float:left;">{{receivedData.headerTitle}}</mat-label>
    <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
</div>
<mat-dialog-content class="mat-typography" style="padding-top: 20px;">
    <form #mmsAdminModalForm="ngForm" autocomplete="off">

        <div fxLayout="row wrap">

            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

            <!--EPPOS-4436--><div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                  <input matInput placeholder="User Name" name="userName" #userName="ngModel" id="userName"
                    [(ngModel)]="storeData.userName" required [disabled]="receivedData.headerTitle=='Update User '"
                    appTrimSpaceInput autocomplete="new-password"
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"> <!-- Ensure that @ is present -->

                  <mat-error *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="invalid-feedback">
                    <ng-container *ngIf="userName.errors?.required">Please enter user name</ng-container>
                    <ng-container *ngIf="userName.errors?.pattern">User name must contain valid email</ng-container>
                  </mat-error>
                </mat-form-field>
              </div> <!--EPPOS-4436-->


              <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Contact No." name="contactNotemp" #contactNotemp="ngModel"
                            id="contactNotemp" [(ngModel)]="MMSAdmin.contactNo" required minlength="10" maxlength="13"
                            mask='(000)000-0000'>
                        <mat-error *ngIf="contactNotemp.invalid && (contactNotemp.dirty || contactNotemp.touched)"
                            class="invalid-feedback">
                            Please enter contact number
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>
        </div>

        <div fxLayout="row wrap" style="padding-top: 10px;">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Last Name" name="lName" #lName="ngModel" id="lName"
                            [(ngModel)]="MMSAdmin.lName" required appTrimSpaceInput>
                        <mat-error *ngIf="lName.invalid && (lName.dirty || lName.touched)" class="invalid-feedback">
                            Please enter last name
                        </mat-error>
                    </mat-form-field>

                </div>
                <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
                <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="First Name" name="fName" #fName="ngModel" id="fName"
                            [(ngModel)]="MMSAdmin.fName" required appTrimSpaceInput>
                        <mat-error *ngIf="fName.invalid && (fName.dirty || fName.touched)" class="invalid-feedback">
                            Please enter first name
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>
        </div>

        <div fxLayout="row wrap" style="padding-top:10px;padding-bottom: 15px;">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-checkbox [(ngModel)]="MMSAdmin.isActive" name="isActive" class="custom-checkbox">
                        Is Active
                    </mat-checkbox>
                </div>
                <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
                <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-checkbox [(ngModel)]="MMSAdmin.isLocked" name="isLocked" class="custom-checkbox">
                        Is Locked?
                    </mat-checkbox>
                </div>
            </div>
        </div>

    </form>
</mat-dialog-content>

<hr>

<mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="center center" style="margin: 10px;">
    <button mat-stroked-button mat-dialog-close style="background-color: #6C747E;width:
          80px;height: 40px;color: #FFFFFF; border-radius:
          4px;font-size: 14px;text-align: center;line-height: 20px;">
        Close
    </button>

    <button mat-flat-button color="warn" (click)="DeleteMMSAdmin(MMSAdmin)"
        *ngIf="receivedData.headerTitle=='Update User '" cdkFocusInitial [disabled]="mmsAdminModalForm.invalid"
        style="background-color: #6C747E;width:
          80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;">Delete</button>

    <button mat-flat-button color="primary" (click)="InsertMMSAdmin(MMSAdmin)"
        *ngIf="receivedData.headerTitle=='Create User '" style="background-color: #F59120;width:
           80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
        [disabled]="mmsAdminModalForm.invalid " cdkFocusInitial>Create</button>

    <button mat-flat-button color="primary" (click)="UpdateMMSAdmin(MMSAdmin)"
        *ngIf="receivedData.headerTitle=='Update User '" style="background-color: #F59120;width:
        80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
        [disabled]="mmsAdminModalForm.invalid" cdkFocusInitial>Update</button>

</mat-dialog-actions>
