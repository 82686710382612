<div class="example-container" [class.example-is-mobile]="mobileQuery.matches" *ngIf="shouldRun">
  <mat-nav-list>
    <ng-container *ngFor="let item of menuItems.getMenuitem()">
      <!-- Parent Items without Children -->
      <ng-container *ngIf="!item.childMenu || item.childMenu.length === 0">
        <mat-list-item [routerLink]="item.state" [class.selected]="selectedItem === item"  (click)="onMenuItemClick(item)">
          <div fxLayout="row" fxLayoutAlign="start center"
            style="padding-top: 10px; color: #ADADAD; font-size: 13px; font-weight: normal; text-align: left; line-height: 20px;">
            <img *ngIf="item.imageUrl" [src]="item.imageUrl" [matTooltip]="item.name" alt="Image"
              style="width: 20px; height: 20px;">
            <mat-label style="padding-left: 8px; vertical-align: middle; padding-top: 4px;"
              (mouseover)="item.isHovered = true" (mouseout)="item.isHovered = false" [id]="item.state+ 'Menu'"
              (click)="onMenuItemClick(item)" [class.hovered]="item.isHovered" [class.selected]="selectedItem === item"
              *ngIf="!sidenavOpened">{{ item.name }}</mat-label>
          </div>
        </mat-list-item>
      </ng-container>

      <!-- Parent Items with Children -->
      <ng-container *ngIf="item.childMenu && item.childMenu.length > 0">
        <div style="display: flex; align-items: center; cursor: pointer; padding-left: 15px; padding-top: 10px;"
          (click)="item.expanded = !item.expanded" fxLayout="row" fxLayoutAlign="space-between center">
          <div style="display: flex; align-items: center;">
            <img [src]="item.imageUrl" alt="EDI Image" style="width: 20px; height: 20px; margin-right: 8px;" [matTooltip]="item.name">
            <mat-label *ngIf="!sidenavOpened"
              style="padding-top: 10px; color: #ADADAD; font-size: 13px; font-weight: normal; text-align: left; line-height: 20px;">
              {{item.name}}
            </mat-label>
          </div>
        </div>
        <mat-list *ngIf="item.expanded">
          <mat-list-item *ngFor="let child of item.childMenu" [routerLink]="child.state" (click)="onMenuItemClick(child)" [class.selected]="selectedItem === child" >
            <div fxLayout="row" fxLayoutAlign="start center"
              style="padding-top: 10px; color: #ADADAD; font-size: 13px; font-weight: normal; text-align: left; line-height: 20px;">
              <mat-label style="padding-left: 10px; vertical-align: middle; padding-top: 4px;"
                (mouseover)="child.isHovered = true" (mouseout)="child.isHovered = false"
                [class.hovered]="child.isHovered" (click)="onMenuItemClick(child)"
                [class.selected]="selectedItem === child" *ngIf="!sidenavOpened">{{ child.name }}
              </mat-label>
            </div>
          </mat-list-item>
        </mat-list>
      </ng-container>
    </ng-container>
  </mat-nav-list>
</div>