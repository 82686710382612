import { Injectable } from "@angular/core";
import { BaseHttpService } from "../utility/BaseHttpService"; // Import the base service
import { constant } from "../constant/constant";

@Injectable({
  providedIn: 'root'
})

export class MPOSStoreAdminService {

  constructor(private baseHttpService: BaseHttpService) { }

  Insert(store: any) {
    return this.baseHttpService.post<any>((constant.MPOS_USER_SERVICE_URL + constant.INSERT_MPOS_STORE_ADMIN), store, constant.httpClientHeader.headers);
  }

  Update(store: any) {
    return this.baseHttpService.post<any>((constant.MPOS_USER_SERVICE_URL + constant.UPDATE_MPOS_STORE_ADMIN), store, constant.httpClientHeader.headers);
  }

  Reset(resetPasswordRequest: any) {
    return this.baseHttpService.post<any>((constant.MPOS_USER_SERVICE_URL + constant.RESET_PASSWORD_MPOS_STORE_ADMIN), resetPasswordRequest, constant.httpClientHeader.headers);
  }

  Delete(store: any) {
    return this.baseHttpService.post<any>((constant.MPOS_USER_SERVICE_URL + constant.DELETE_MPOS_STORE_ADMIN), store, constant.httpClientHeader.headers);
  }

  getPrimeRxPOSAdminByUserName(user: any) {
    return this.baseHttpService.post<any>((constant.MPOS_USER_SERVICE_URL + constant.GET_MPOS_STORE_ADMIN_BY_USERNAME), user, constant.httpClientHeader.headers);
  }
}
